import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import Teachers from '../pages/Teachers';
import Affiliated from '../pages/Affiliated';

import Categories from '../pages/Categories';
import BannersPartners from '../pages/BannersPartners';
import Videos from '../pages/Videos';
import VideoEdit from '../pages/VideoEdit';

import BaseLayout from '../components/BaseLayout';
import { MenuProvider } from '../hooks/Menu';

const Routes: React.FC = () => (
  <MenuProvider>
    <Switch>
      <Route exact path="/" component={Login} />
      <BaseLayout>
        <Route path="/professores" component={Teachers} isPrivate />
        <Route path="/categorias" component={Categories} isPrivate />
        <Route
          path="/banners-e-parceiros"
          component={BannersPartners}
          isPrivate
        />
        <Route exact path="/videos" component={Videos} isPrivate />
        <Route
          path="/video/:type?/:idCourse?"
          component={VideoEdit}
          isPrivate
        />
        <Route path="/afiliados" component={Affiliated} isPrivate />
      </BaseLayout>
    </Switch>
  </MenuProvider>
);

export default Routes;
