import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto 20px;
  transition: box-shadow 0.4s;
  box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 10%);
  padding: 2rem;
  border-radius: 4px;
  position: relative;
  flex-direction:column ;

  .infos {
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;

    h2{
    font-size: 26px;
    line-height: 1.3;
    font-weight: 600;
    }

    span {
      margin-top: 0;
    }
  }

  .views {
    width:100% ;
    margin-top: 50px;

    display:flex ;
    flex-direction:row;
    align-items:center ;
    justify-content:space-between ;

    a{
      text-decoration: none ;
      color:gray;
    }
    
    div{
      display:flex ;
      flex-direction:row;
      align-items:center ;
      justify-content:space-between ;
      margin-right:15px ;
    }
    
    h3{
      font-size:2rem ;
      font-weight: 400;
      line-height: 1.5em;
    }

    h5{
      font-size:0.9rem ;
      font-weight: 400;
      line-height: 1.5em;
    }
    button {
      position: relative;
      display: inline-flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      height: 40px;
      border-radius: 8px;
      padding-left: 24px;
      padding-right: 24px;
      text-decoration: none;
      user-select: none;
      border-radius: 8px;
      cursor: pointer;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin-top: 0.5rem;
      color: #fff;
      border: 1px solid #21a4ac;
      background-color: #21a4ac;
    }


    }
  }

  p {
    grid-column: 1/3;
    margin-top: 20px;
  }

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;

    svg {
      color: #aaa;
    }
  }
`;

export const EditBtn = styled.button`
  right: 40px !important;
`;

export const CopyButton = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 40px;
  border-radius: 8px;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  user-select: none;
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 0.5rem;
  color: red;
  border: 1px solid #21a4ac;
  background-color: #21a4ac;
`;

export const DeleteBtn = styled.button``;

interface IModalContainerProps {
  color: string;
}

export const ModalContent = styled.div<IModalContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 20px;
  }

  table {
    th {
      width: 100px;
      height: 30px;
      text-transform: uppercase;
      background-color: #5ecb43;
      color: #fff;
    }

    td {
      text-align: center;
    }
  }

  button {
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    background-color: ${({ color }) => color};
    color: #fff;

    &:hover {
      background-color: ${({ color }) => shade(0.2, color)};
    }
  }
`;

export const ActionContainer = styled.div`
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    position: inherit;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 40px;
    border-radius: 8px;
    padding-left: 24px;
    padding-right: 24px;
    text-decoration: none;
    user-select: none;
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 0.5rem;
    color: #21a4ac;
    border: 1px solid #21a4ac;
    background-color: #fff;
  }
`;
export const DeleteReviewTxt = styled.button`
  position: absolute;
  right: 10px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  color: #ff4545;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    color: #404040;
  }
`;
