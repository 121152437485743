import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface IProps {
  name: string;
  label?: string;
  prevImg?: string | File;
}

type InputProps = JSX.IntrinsicElements['input'] & IProps;

const ThumbnailInput: React.FC<InputProps> = ({
  name,
  label,
  prevImg,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState<string | File | undefined>(
    defaultValue,
  );

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;

    if (!file) {
      setPreview(undefined);
    } else {
      const previewURL = URL.createObjectURL(file.item(0));
      setPreview(previewURL);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files',
      clearValue(ref: HTMLInputElement) {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';
        setPreview(undefined);
      },
      setValue(ref: HTMLInputElement, value: string) {
        // ref.files[0] = value;
        setPreview(value);
      },
    });

    if (prevImg) {
      if (typeof prevImg === 'string') setPreview(prevImg);
      if (prevImg instanceof File) setPreview(URL.createObjectURL(prevImg));
    }
  }, [fieldName, prevImg, registerField]);

  return (
    <>
      <Container htmlFor={name} style={{ backgroundImage: `url(${preview})` }}>
        {label && <strong>{label}</strong>}
        <input
          type="file"
          ref={inputRef}
          onChange={handlePreview}
          id={name}
          {...rest}
        />
      </Container>
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </>
  );
};

export default ThumbnailInput;
