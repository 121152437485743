import { shade } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 20px;
    width: 90%;
    padding: 10px 15px;

    .tab-btn-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 1rem;

      .tab-btn {
        border: none;
        background: 0;
        color: #333;
        font-weight: 600;
        font-size: 1rem;

        &.show {
          border-bottom: 5px solid #21a4ac;
        }
      }
    }

    select.select {
      padding: 10.5px 2rem;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: #333;
    }
  }

  .tab-panel {
    display: none;

    &.show {
      display: grid;
    }
  }
`;

export const Filter = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 20px;
  width: 90%;
  padding: 10px 15px;

  .search-container {
    display: flex;
    align-items: center;
    border: 1px solid #aaa;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    margin: 0 2rem;
    width: 100%;

    input {
      width: 100%;
      padding: 5px 10px;
      margin-left: 0.5rem;
      border: 0;
    }
  }

  select.select {
    padding: 10.5px 2rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #333;
  }
`;

export const Lista = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  margin: 0 auto 20px;
  width: 90%;
  padding: 10px 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const BtnAdd = styled(Link)`
  text-decoration: none;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #21a4ac;
  color: #fff;
  background-color: #21a4ac;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${shade(0.2, '#21a4ac')};
  }
`;
