import styled from 'styled-components';
import { Form as Unform } from '@unform/web';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  width: 90%;
  padding: 10px 15px;

  h1 {
    align-self: flex-start;
  }
`;

export const Form = styled(Unform)`
  display: grid;
  grid-column-gap: 3rem;
  width: 100%;
  max-width: 1024px;
  margin: 2rem auto 0;

  .radio-container {
    grid-column: 1/2;
  }

  .grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 2rem;
    padding: 2rem 0;
  }

  .left {
    grid-column: 1/2;
  }

  .right {
    grid-column: 2/3;
  }

  .videos-infos-container {
    grid-column: 1/3;
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    .videos-item-container {
      position: relative;
      margin-top: 2rem;
      padding: 1rem;
      border: 1px solid #aaa;
      border-radius: 8px;
      background-color: #fff;

      &::before {
        content: '';
        position: absolute;
        left: -40px;
        top: 0;
        height: 60px;
        width: 30px;
        background-color: #aaa;
      }

      button.delete-video {
        position: absolute;
        top: 3px;
        right: 3px;
        background: 0;
        border: 0;
        cursor: pointer;
      }
    }

    button.add-video {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0.5rem 2rem;
      font-size: 1.5rem;
      border-radius: 4px;
      border: 0;
      color: #fff;
      background-color: #21a4ac;
      cursor: pointer;
      transition: 300ms ease-in-out;

      :hover {
        background-color: ${shade(0.2, '#21a4ac')};
      }
    }
  }

  button[type="submit"] {
    grid-column: 1/3;
    justify-self: center;
    padding: 1rem 4rem;
    font-size: 1.5rem;
    border-radius: 4px;
    border: 0;
    color: #fff;
    background-color: #21a4ac;
    cursor: pointer;
    transition: 300ms ease-in-out;

    :hover {
      background-color: ${shade(0.2, '#21a4ac')};
    }
  }
`;

export const Errors = styled.ul`
  margin-top: 20px;
  color: #ff4545;
  background-color: #ff454533;
  padding: 10px 10px 10px 30px;
  border-radius: 10px;
`;
