import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isCheckbox: boolean;
}

export const Container = styled.div<IContainerProps>`
  .input {
    display: flex;
    align-items: center;
    background: transparent;
    width: 100%;
    font-size: 16px;

    ${props =>
      !props.isCheckbox &&
      css`
        padding: 10px 16px;
        border-bottom: 1px solid;
        border-color: #b7b7cc;
      `}

    h1 {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
    }

    ${props =>
      props.isFocused &&
      css`
        color: #21a4ac;
        border-color: #21a4ac;
      `}

    ${props =>
      props.isFilled &&
      css`
        color: #21a4ac;
      `}

  input {
      flex: 1;
      background: transparent;
      border: 0;
      color: #404040;

      &::placeholder {
        color: #bdbec2;
      }
    }

    svg {
      margin-right: 16px;
    }
  }

  & + div {
    ${props =>
      !props.isCheckbox &&
      css`
        margin-top: 24px;
      `}
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 15px;

  svg {
    margin: 0;
  }

  span {
    background: #ff4d4d;
    color: #fff;

    &::before {
      border-color: #ff4d4d transparent;
    }
  }
`;
