import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.header`
  background: #21a4ac;

  padding: 5px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    .menu-btn {
      display: none;
      align-items: center;
      padding: 0;
      margin: 0 20px 0 0;

      svg {
        color: #fff;
      }
    }

    img {
      height: 30px;
    }

    @media (max-width: 323px) {
      .menu-btn {
        margin: 0;
      }

      h1 {
        display: none;
      }
    }
  }

  button {
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: transparent;
    color: #fff;
    padding: 10px;
    transition: color 0.3s;
    position: relative;

    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }

    img {
      width: 30px;
    }

    p::after {
      content: '';
      width: 0;
      height: 1.5px;
      background: #fff;
      position: absolute;
      bottom: 8px;
      left: 10px;
      transition: width 0.5s;
    }

    &:hover {
      p::after {
        width: 50px;
      }
    }
  }

  @media screen and (max-width: 950px) {
    div {
      .menu-btn {
        display: flex;
      }
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #404040;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 40px;
      margin-left: 10px;
    }
  }

  strong {
    margin-top: 20px;
    align-self: flex-start;
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #333;

    &:focus {
      border-color: #21a4ac;
    }
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  textarea {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid #333;

    &:focus {
      border-color: #21a4ac;
    }
  }

  button {
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    background-color: #21a4ac;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 40px;
    }

    &:hover {
      background-color: ${shade(0.2, '#21a4ac')};
    }

    &:disabled {
      background-color: #ddd;
      cursor: default;
    }
  }
`;
