import styled from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21a4ac;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  position: relative;

  strong {
    margin-bottom: 10px;
  }

  input {
    visibility: hidden;
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
    height: 0;
  }

  & + label {
    margin-top: 24px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  width: 20px !important;
  margin-left: 15px;

  svg {
    margin: 0;
  }

  span {
    background: #ff4d4d;
    color: #fff;

    &::before {
      border-color: #ff4d4d transparent;
    }
  }
`;
