import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<IContainerProps>`
  .input {
    display: flex;
    align-items: center;
    background: transparent;
    width: 100%;
    font-size: 16px;
    border: 1px solid #b7b7cc;
    border-radius: 8px;
    padding: 10px 16px;

    ${props =>
      props.isFocused &&
      css`
        color: #21a4ac;
        border-color: #21a4ac;
      `}

    ${props =>
      props.isFilled &&
      css`
        color: #21a4ac;
      `}

  textarea {
      flex: 1;
      background: transparent;
      border: 0;
      color: #404040;
      font-family: 'Poppins', sans-serif;

      &::placeholder {
        color: #bdbec2;
      }
    }

    svg {
      margin-right: 16px;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 15px;

  svg {
    margin: 0;
  }

  span {
    background: #ff4d4d;
    color: #fff;

    &::before {
      border-color: #ff4d4d transparent;
    }
  }
`;
