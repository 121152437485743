/* eslint-disable no-restricted-globals */
import React, { useEffect, createRef, useState, useCallback } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import Loading from '../../components/Loading';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import { Container } from './styles';

export interface ISubCategory {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
}

export interface ICategory {
  id: string;
  name: string;
  subCategories: ISubCategory[];
}

const Categories: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [idCategory, setIdCategory] = useState<string>();
  const [idSubCategory, setIdSubCategory] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<string>();

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const inputCateg = createRef<HTMLInputElement>();
  const inputSub = createRef<HTMLInputElement>();
  const selectCateg = createRef<HTMLSelectElement>();

  useEffect(() => {
    async function loadTeachers(): Promise<void> {
      setIsLoading(() => true);
      try {
        const res = await api.get('/categories', {
          headers: { authorization: token },
        });

        setCategories(res.data);
      } catch (err) {
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title: 'Ops...',
            description:
              err?.response?.data.message ||
              'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
        }
      } finally {
        setIsLoading(() => false);
      }
    }

    loadTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const handleAddCategory = useCallback(async () => {
    const input = inputCateg.current?.value;

    try {
      if (!input) throw new Error('A categoria não pode ser vazia');

      await api.post(
        '/save-category',
        { idCategory, category: input },
        { headers: { authorization: token } },
      );

      addToast({
        title: `Categoria ${idCategory ? 'editada' : 'criada'} com sucesso!`,
        type: 'success',
      });

      setIdCategory(undefined);
      setReload(old => !old);
      inputCateg.current?.setAttribute('value', '');
    } catch (err) {
      if (err?.response?.status === 401) {
        addToast({
          title: 'Você nao tem permissão para isto',
          type: 'error',
        });
        signOut();
      } else {
        addToast({
          title:
            err.response?.data.message ||
            err.message ||
            'Ocorreu algum erro, atualize a página',
          type: 'error',
        });
        inputSub.current?.focus();
      }
    }
  }, [addToast, idCategory, inputCateg, inputSub, signOut, token]);

  const handleAddSubCategory = useCallback(async () => {
    const input = inputSub.current?.value;
    const select = selectCateg.current?.value;

    try {
      if (!input) throw new Error('A subcategoria não pode ser vazia');
      if (!select) throw new Error('A categoria não pode ser vazia');

      await api.post(
        '/save-subcategory',
        { idSubCategory, category: select, subCategory: input },
        { headers: { authorization: token } },
      );

      addToast({
        title: `Subcategoria ${
          idSubCategory ? 'editada' : 'criada'
        } com sucesso!`,
        type: 'success',
      });

      setIdSubCategory(undefined);
      setSelectedCategory(undefined);
      setReload(old => !old);
      inputSub.current?.setAttribute('value', '');
    } catch (err) {
      if (err?.response?.status === 401) {
        addToast({
          title: 'Você nao tem permissão para isto',
          type: 'error',
        });
        signOut();
      } else {
        addToast({
          title:
            err.response?.data.message ||
            err.message ||
            'Ocorreu algum erro, atualize a página',
          type: 'error',
        });
        inputSub.current?.focus();
      }
    }
  }, [addToast, idSubCategory, inputSub, selectCateg, signOut, token]);

  const handleDeleteCategory = useCallback(
    async id => {
      try {
        if (confirm('Tem certeza de que deseja deletar?')) {
          await api.delete('/delete-category', {
            data: { idCategory: id },
            headers: { authorization: token },
          });

          addToast({
            title: 'Categoria deletada com sucesso!',
            type: 'success',
          });
          setReload(old => !old);
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title:
              err.response?.data.message ||
              err.message ||
              'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
          inputSub.current?.focus();
        }
      }
    },
    [addToast, inputSub, signOut, token],
  );

  const handleDeleteSubCategory = useCallback(
    async id => {
      try {
        if (confirm('Tem certeza de que deseja deletar?')) {
          await api.delete('/delete-subcategory', {
            data: { idSubCategory: id },
            headers: { authorization: token },
          });

          addToast({
            title: 'Subcategoria deletada com sucesso!',
            type: 'success',
          });
          setReload(old => !old);
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title:
              err.response?.data.message ||
              err.message ||
              'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
          inputSub.current?.focus();
        }
      }
    },
    [addToast, inputSub, signOut, token],
  );

  return (
    <>
      <Container>
        <div className="categories">
          <div>
            <strong>Adicionar categoria</strong>
            {idCategory && (
              <button
                type="button"
                className="new-category"
                onClick={() => {
                  setIdCategory(undefined);
                  inputCateg.current?.setAttribute('value', '');
                }}
              >
                Nova categoria
              </button>
            )}
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Digite e clique em OK"
              ref={inputCateg}
            />
            <button type="button" onClick={handleAddCategory}>
              OK
            </button>
          </div>
        </div>
        <div className="categories">
          <div>
            <strong>Adicionar subcategoria</strong>
            {idSubCategory && (
              <button
                type="button"
                className="new-category"
                onClick={() => {
                  setIdSubCategory(undefined);
                  setSelectedCategory(undefined);
                  inputSub.current?.setAttribute('value', '');
                }}
              >
                Nova subcategoria
              </button>
            )}
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Digite e clique em OK"
              ref={inputSub}
            />
            <button type="button" onClick={handleAddSubCategory}>
              OK
            </button>
          </div>
          <select
            name="categorySelect"
            defaultValue=""
            value={selectedCategory || ''}
            className="select"
            ref={selectCateg}
            onChange={event => setSelectedCategory(event.target.value)}
          >
            <option value="" disabled>
              Selecione uma categoria
            </option>
            {categories?.map(categ => (
              <option value={categ.id} key={categ.id}>
                {categ.name}
              </option>
            ))}
          </select>
        </div>
        <div className="categories">
          <div className="category-list">
            {categories?.map(categ => (
              <div className="category-item" key={categ.id}>
                <span>{categ.name}</span>
                <button
                  className="edit"
                  title={`Editar ${categ.name}`}
                  onClick={() => {
                    setIdCategory(categ.id);
                    inputCateg.current?.setAttribute('value', categ.name);
                  }}
                >
                  <FaPencilAlt color="#333" />
                </button>
                <button
                  className="delete"
                  title={`Deletar ${categ.name}`}
                  onClick={() => handleDeleteCategory(categ.id)}
                >
                  <GrClose color="#ddd" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="categories">
          <div className="subcategory-list">
            {categories?.map(categ => (
              <div className="category-item" key={categ.id}>
                <strong>{categ.name}</strong>
                {categ.subCategories.map(sub => (
                  <div className="subcategory-item" key={sub.id}>
                    <span>{sub.name}</span>
                    <button
                      className="edit"
                      title={`Editar ${sub.name}`}
                      onClick={() => {
                        setSelectedCategory(categ.id);
                        setIdSubCategory(sub.id);
                        inputSub.current?.setAttribute('value', sub.name);
                      }}
                    >
                      <FaPencilAlt color="#333" />
                    </button>
                    <button
                      className="delete"
                      title={`Deletar ${sub.name}`}
                      onClick={() => handleDeleteSubCategory(sub.id)}
                    >
                      <GrClose color="#ddd" />
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Container>
      <Loading show={isLoading} />
    </>
  );
};

export default Categories;
