import React, { useMemo, createRef, useState, useCallback } from 'react';

import { FiLogOut, FiList } from 'react-icons/fi';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { Container } from './styles';
import { useMenu } from '../../hooks/Menu';
import Modal from '../Modal';
import { ModalContent } from './styles';
import Input from './Input';
import Textarea from './Textarea';
import PushLogo from '../../assets/push-notification-icon.png';
import loadingSVG from '../../assets/loading.svg';
import logo from '../../assets/icon_logo_white.png';
import api from '../../services/api';

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { handleShowMenu, isPublic } = useMenu();
  const { signOut, token } = useAuth();
  const { addToast } = useToast();

  const inputRef = createRef<HTMLInputElement>();
  const textRef = createRef<HTMLTextAreaElement>();

  const handleSend = useCallback(async () => {
    setLoading(true);
    const inpt = inputRef.current?.value;
    const txt = textRef.current?.value;

    if (!inpt) {
      inputRef.current?.focus();
      setLoading(false);
      return;
    }
    if (!txt) {
      textRef.current?.focus();
      setLoading(false);
      return;
    }

    try {
      await api.post(
        '/send-push',
        { title: inpt, text: txt },
        {
          headers: { Authorization: token },
        },
      );

      addToast({
        title: 'Enviado!',
        description: 'A notificação foi enviada com sucesso!',
        type: 'success',
      });

      if (inputRef.current) inputRef.current.value = '';
      if (textRef.current) textRef.current.value = '';
    } catch (err) {
      if (err.resonse.status === 401) {
        addToast({
          title: 'Você nao tem permissão para isto',
          type: 'error',
        });
        signOut();
      } else {
        addToast({
          title: 'Ops... ocorreu algum, tente novamente',
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [addToast, inputRef, signOut, textRef, token]);

  const NotificationModal = (): JSX.Element =>
    useMemo(
      () => (
        <Modal isOpen={open} onClose={() => setOpen(false)}>
          <ModalContent>
            <h1>
              Notificações <img src={PushLogo} alt="Enviar notificação" />
            </h1>
            <p>
              Escreva abaixo e envie uma notificação push para o smartphone de
              todos os usuários cadastrados que contenham o app da iDOL
              instalado
            </p>
            <strong>Título</strong>
            <Input type="text" innerRef={inputRef} maxLength={30} />
            <strong>Descrição</strong>
            <Textarea
              innerRef={textRef}
              rows={3}
              maxLength={80}
              placeholder="Abra o app da iDOL agora mesmo e veja os novos ídolos cadastrados"
            />
            <button type="button" onClick={handleSend} disabled={loading}>
              {loading ? <img src={loadingSVG} alt="Enviando..." /> : 'Enviar'}
            </button>
          </ModalContent>
        </Modal>
      ),
      [],
    );

  return (
    <>
      <Container>
        <div>
          <button type="button" className="menu-btn" onClick={handleShowMenu}>
            <FiList size={30} />
          </button>
          <img src={logo} alt="Logo iSPORT" />
        </div>
        {!isPublic && (
          <nav>
            <div>
              <button
                type="button"
                onClick={() => {
                  signOut();
                  addToast({ title: 'Deslogado com sucesso' });
                }}
              >
                <p>sair</p>
                <FiLogOut size={20} />
              </button>
            </div>
          </nav>
        )}
      </Container>
      {open && <NotificationModal />}
    </>
  );
};

export default Header;
