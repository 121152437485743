import styled from 'styled-components';
import { Form as Unform } from '@unform/web';
import { shade } from 'polished';
import bgLogin from '../../assets/bg-login.jpg';

export const Container = styled.div`
  display: flex;
`;

export const Sides = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &.left {
    background-image: url(${bgLogin});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 34px;

    .container {
      background-color: rgba(81, 190, 196, 0.5);
      width: 100%;
      height: 100%;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    img {
      max-width: 60%;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &.right {
    background-color: #fafafa;
    padding: 10px;
  }
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  color: #21a4ac;
  width: 250px;

  h1 {
    font-size: 44px;
  }

  h3 {
    margin-bottom: 50px;
    font-size: 25px;
    font-weight: 300;
  }

  span {
    display: flex;
    align-items: center;

    img {
      height: 30px;
      width: 30px;
      margin: 0 0 0 10px;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 40px;
  color: #333;
  text-align: center;
  border-radius: 5px;
  margin-top: 70px;
  border: none;
  background: #21a4ac;
  transition: background-color 0.2s;
  color: #fff;

  &:hover {
    background: ${shade(0.2, '#21a4ac')};
  }
`;
