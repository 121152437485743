/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import Banner from './Banner';
import ModalEditBanner from './ModalEditBanner';
import ModalEditPartner from './ModalEditPartner';
import Partner from './Partner';
import { BtnAdd, Container } from './styles';

export interface ISubCategory {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
}

export interface IBanner {
  id: string;
  page: string;
  image: string;
  order: string;
  link: string;
  isAd: string;
}

export interface IPartner {
  id: string;
  order: string;
  image: string;
  link: string;
  alt: string;
}

const BannersPartners: React.FC = () => {
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showModaleditBanner, setShowModaEditBanner] = useState(false);
  const [showModaleditPartner, setShowModaEditPartner] = useState(false);

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    async function loadTeachers(): Promise<void> {
      setIsLoading(() => true);
      try {
        const { data } = await api.get('/banners-partners', {
          headers: { authorization: token },
        });

        setBanners(data.banners);
        setPartners(data.partners);
      } catch (err) {
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title: 'Ops...',
            description:
              err?.response?.data.message ||
              'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
        }
      } finally {
        setIsLoading(() => false);
      }
    }

    loadTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      <Container>
        <div className="header">
          <BtnAdd type="button" onClick={() => setShowModaEditBanner(true)}>
            Adcionar Banner
          </BtnAdd>
          <BtnAdd type="button" onClick={() => setShowModaEditPartner(true)}>
            Adcionar Parceiro
          </BtnAdd>
        </div>
        <strong>Parceiros</strong>
        <div className="partners">
          {partners.map(v => (
            <Partner
              key={v.id}
              partner={v}
              reload={() => setReload(old => !old)}
            />
          ))}
        </div>
        <strong>Banners</strong>
        <div className="banners">
          {banners.map(v => (
            <Banner
              key={v.id}
              banner={v}
              reload={() => setReload(old => !old)}
            />
          ))}
        </div>
      </Container>
      <Loading show={isLoading} />
      {showModaleditBanner && (
        <ModalEditBanner
          onClose={() => setShowModaEditBanner(false)}
          onSuccess={() => setReload(old => !old)}
          open={showModaleditBanner}
        />
      )}
      {showModaleditPartner && (
        <ModalEditPartner
          onClose={() => setShowModaEditPartner(false)}
          onSuccess={() => setReload(old => !old)}
          open={showModaleditPartner}
        />
      )}
    </>
  );
};

export default BannersPartners;
