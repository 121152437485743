import React, { useRef, useCallback, useState, useEffect } from 'react';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import validateVideoEdit from './validation';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import toBase64 from '../../utils/toBase64';
import api from '../../services/api';
import { Form, Container } from './styles';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import getValidationErrors from '../../utils/getValidationErrors';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { ICategory } from '../Categories';
import { ITeacher } from '../Teachers';
import Select from '../../components/Select';
import ThumbnailInput from '../../components/ThumbnailInput';
import RadioInput from '../../components/RadioInput';
import VideoInfoForm from '../../components/VideoInfoForm';
import { FaTrashAlt } from 'react-icons/fa';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

interface IVideoDetail {
  category: string;
  duration: number;
  gratuito: boolean;
  id: string;
  image: string;
  long_description: string;
  name: string;
  short_description: string;
  subCategory: string;
  teacher: string;
}

export interface IVideoValuesTypes {
  id?: string;
  video: string;
  thumbnail: File;
  name: string;
  link: string;
  duration: string;
  description: string;
}

interface ISendData {
  formerType: string;
  hasImage: boolean;
  image: File[];
  long_description: string;
  name: string;
  short_description: string;
  subCategories: string | { idCategory: string; idSubCategory: string };
  teacher: string;
  type: string | null;
  video: {
    description: '';
    duration: '';
    name: '';
    thumbnail: FileList;
  }[];
}

const VideoEdit: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [videoDetails, setVideoDetails] = useState<IVideoDetail>();
  const [teachers, setTeachers] = useState<ITeacher[]>();
  const [categories, setCategories] = useState<ICategory[]>();
  const [videos, setVideos] = useState<IVideoValuesTypes[]>([
    {} as IVideoValuesTypes,
  ]);

  const { token, signOut } = useAuth();
  const { addToast } = useToast();
  const { type, idCourse } = useParams<{ type?: string; idCourse?: string }>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      try {
        const { data } = await api.get('/video-detail', {
          params: { type, idCourse },
          headers: { authorization: token },
        });

        setVideoDetails(data.detail);
        setTeachers(data.teachers);
        setCategories(data.categories);
        if (data.detail?.videos.length > 0) {
          setVideos(
            data.detail.videos.length > 0
              ? data.detail.videos
              : [{} as IVideoValuesTypes],
          );
        }
      } catch (error) {
        const err = error as any;
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title:
              err.response?.data?.message ||
              'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
        }
      }

      setLoading(false);
    }

    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reorder = useCallback(
    (list: IVideoValuesTypes[], startIndex: number, endIndex: number) => {
      const result = list;
      const [removed] = result.splice(startIndex, 1);
      const currentForm = formRef.current;

      result.splice(endIndex, 0, removed);

      result.forEach((v, i) => {
        currentForm?.clearField(`video[${i}].name`);
        currentForm?.clearField(`video[${i}].duration`);
        currentForm?.clearField(`video[${i}].thumbnail`);
        currentForm?.clearField(`video[${i}].link`);
        currentForm?.clearField(`video[${i}].description`);

        currentForm?.setFieldValue(`video[${i}].name`, v.name);
        currentForm?.setFieldValue(`video[${i}].duration`, v.duration);
        currentForm?.setFieldValue(`video[${i}].thumbnail`, v.thumbnail);
        currentForm?.setFieldValue(`video[${i}].link`, v.link);
        currentForm?.setFieldValue(`video[${i}].description`, v.description);
      });

      currentForm?.setFieldValue(`video[${endIndex}].name`, removed.name);
      currentForm?.setFieldValue(
        `video[${endIndex}].duration`,
        removed.duration,
      );
      currentForm?.setFieldValue(
        `video[${endIndex}].thumbnail`,
        removed.thumbnail,
      );
      currentForm?.setFieldValue(`video[${endIndex}].link`, removed.link);
      currentForm?.setFieldValue(
        `video[${endIndex}].description`,
        removed.description,
      );

      console.log(result);
      console.log(startIndex, endIndex);

      return result;
    },
    [],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      console.log(result);
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const reorderedVideos = reorder(
        videos,
        result.source.index,
        result.destination.index,
      );

      setVideos(reorderedVideos);
    },
    [reorder, videos],
  );

  const handleInfosSubmit = useCallback(
    async (data: ISendData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);

        const inputValues = {
          ...data,
          type: data.type || type,
          hasId: !!videoDetails?.id,
          image: data.image ? Object.values(data.image) : [videoDetails?.image],
          video: data.video.map(v => ({
            ...v,
            thumbnail: v.thumbnail.length > 0 ? Object.values(v.thumbnail) : [],
          })),
        };

        await validateVideoEdit(inputValues);

        const video = [];

        for (const v of inputValues.video) {
          video.push({
            ...v,
            thumbnail:
              v.thumbnail?.length > 0
                ? await toBase64(v.thumbnail?.[0] as File)
                : undefined,
          });
        }

        const payload = {
          id: videoDetails?.id || undefined,
          ...data,
          type: data.type || type,
          image:
            data.image.length > 0
              ? await toBase64(data.image?.[0] as File)
              : undefined,
          video,
        };

        await api.post('/save-video', payload, {
          headers: { authorization: token },
        });

        addToast({
          title: `${videoDetails?.id ? 'Alterado' : 'Salvo'} com sucesso`,
          type: 'success',
        });
        history.push('/videos');
      } catch (error) {
        const err = error as any;
        if (err.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else if (err instanceof ValidationError) {
          const errs = getValidationErrors(err);
          formRef.current?.setErrors(errs);
          // console.log('---', errs);
        } else if (err.response?.status === 400) {
          addToast({
            type: 'error',
            title: 'Ops...',
            description: err.response?.data?.message || 'Tente novamente',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [videoDetails, token, addToast, history, signOut, type],
  );

  return (
    <Container>
      <h1>
        {videoDetails?.name
          ? `Editar ${videoDetails?.name}`
          : 'Cadastrar curso'}
      </h1>
      <Form ref={formRef} onSubmit={handleInfosSubmit}>
        <Input type="hidden" name="formerType" mask="" defaultValue={type} />
        {!videoDetails?.id && (
          <div className="radio-container">
            <RadioInput
              name="type"
              options={[
                { label: 'VideoAula', value: 'aula' },
                { label: 'VideoArtigo', value: 'artigo' },
                { label: 'VideoClinica', value: 'clinica' },
              ]}
              defaultValueSelected={type}
            />
          </div>
        )}
        <div className="grid left">
          <Input
            name="name"
            mask=""
            placeholder="Nome"
            label="Nome"
            defaultValue={videoDetails?.name}
          />
          <Textarea
            name="short_description"
            placeholder="Descrição curta"
            label="Descrição curta"
            defaultValue={videoDetails?.short_description}
            rows={8}
          />
          <Textarea
            name="long_description"
            placeholder="Descrição longa"
            label="Descrição longa"
            defaultValue={videoDetails?.long_description}
            rows={10}
          />
        </div>
        <div className="grid right">
          <ThumbnailInput
            name="image"
            className="input"
            label="Clique para adicionar capa"
            accept="image/jpg,image/jpeg,image/png"
            prevImg={videoDetails?.image}
          />
          {!loading && (
            <>
              <Select
                name="subCategory"
                label="Sub categoria"
                placeholder="Sub categoria"
                options={
                  categories?.map(v => ({
                    label: v.name,
                    options: v.subCategories.map(s => ({
                      label: s.name,
                      value: `${v.id}-${s.id}`,
                    })),
                  })) || []
                }
                onChange={value => console.log(value)}
                defaultValue={
                  videoDetails?.subCategory
                    ? {
                        label: categories
                          ?.find(v => v.id === videoDetails?.category)
                          ?.subCategories.find(
                            s => s.id === videoDetails?.subCategory,
                          )?.name,
                        value: `${
                          categories?.find(v => v.id === videoDetails?.category)
                            ?.id
                        }-${
                          categories
                            ?.find(v => v.id === videoDetails?.category)
                            ?.subCategories.find(
                              s => s.id === videoDetails?.subCategory,
                            )?.id
                        }`,
                      }
                    : undefined
                }
              />
              <Select
                name="teacher"
                label="Professor"
                placeholder="Professor"
                options={
                  teachers?.map(v => ({
                    label: v.name,
                    value: v.id,
                  })) || []
                }
                defaultValue={
                  videoDetails?.teacher
                    ? {
                        label: teachers?.find(
                          v => v.id === videoDetails?.teacher,
                        )?.name,
                        value: teachers?.find(
                          v => v.id === videoDetails?.teacher,
                        )?.id,
                      }
                    : undefined
                }
              />
            </>
          )}
        </div>
        <div className="videos-infos-container">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {videos.map((_, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {innerProvided => (
                        <div
                          key={index}
                          className="videos-item-container"
                          ref={innerProvided.innerRef}
                          {...innerProvided.draggableProps}
                          {...innerProvided.dragHandleProps}
                        >
                          <VideoInfoForm
                            index={index}
                            defaultValues={videos[index]}
                            getValues={values => {
                              let array = [...videos];
                              array[index] = values;
                              setVideos(array);
                            }}
                          />
                          {videos.length !== 1 && (
                            <button
                              type="button"
                              className="delete-video"
                              title="Deletar este video"
                              onClick={() =>
                                setVideos(old => {
                                  const vals = [...old];
                                  vals.splice(index, 1);
                                  return vals;
                                })
                              }
                            >
                              <FaTrashAlt color="#aaa" size={20} />
                            </button>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <button
            type="button"
            className="add-video"
            onClick={() => setVideos(old => [...old, {} as IVideoValuesTypes])}
          >
            mais +
          </button>
        </div>

        <button type="submit" disabled={loading}>
          Salvar
        </button>
      </Form>
      {loading && <Loading show={loading} />}
    </Container>
  );
};

export default VideoEdit;
