import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #fff;
    -webkit-font-smoothing: antialiased;
    color: #333;
  }

  body, input, button {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #333;
  }

  button {
    cursor: pointer;
  }

  #root {
    height: calc(100vh - 52px);
    /* height: 100vh; */
    /* overflow: hidden; */
    width: 100%;
  }
`;
