import React, { useRef, useCallback, useState } from 'react';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input';
import FileInput from '../../../../components/FileInput';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/Auth';
import { useToast } from '../../../../hooks/Toast';
import { ITeacher } from '../..';
import { ModalContent, Form } from './styles';
import toBase64 from '../../../../utils/toBase64';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Textarea from '../../../../components/Textarea';

interface ISendData {
  image: FileList;
  name: string;
  description: string;
  occupation: string;
}

interface IModalReviewProps {
  teacher?: ITeacher;
  open: boolean;
  onClose: () => void;
  closeMenu?: () => void;
  onSuccess: () => void;
}

const ModalEdit: React.FC<IModalReviewProps> = ({
  open,
  onClose,
  teacher,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const handleInfosSubmit = useCallback(
    async (data: ISendData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);

        const Schema = yup.object().shape({
          name: yup.string().required('O campo nome é obrigatório'),
          occupation: yup.string().required('O campo profissão é obrigatório'),
          description: yup.string().required('O campo descrição é obrigatório'),
          hasImage: yup.boolean(),
          image: yup.mixed().when('hasImage', {
            is: true,
            then: yup.array().of(
              yup
                .mixed()
                .required('A imagem é obrigatória')
                .test(
                  'fileFormat',
                  'Formato não suportado',
                  value =>
                    value &&
                    ['image/jpg', 'image/jpeg', 'image/png'].includes(
                      value.type,
                    ),
                ),
            ),
            otherwise: yup
              .array()
              .of(
                yup
                  .string()
                  .url('A URL não é válida')
                  .required('A URL da imagem antiga é obrigatória'),
              ),
          }),
        });

        await Schema.validate(
          {
            ...data,
            hasImage: !!data.image,
            image: data.image ? Object.values(data.image) : [teacher?.image],
          },
          { abortEarly: false },
        );

        const payload = {
          id: teacher?.id || undefined,
          ...data,
          image:
            data.image.length > 0
              ? await toBase64(data.image?.[0] as File)
              : undefined,
        };

        await api.post(`/${teacher?.id ? 'edit' : 'create'}-teacher`, payload, {
          headers: { authorization: token },
        });

        onClose();
        onSuccess();
        addToast({
          title: `${teacher?.id ? 'Alterado' : 'Salvo'} com sucesso`,
          type: 'success',
        });
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else if (err instanceof yup.ValidationError) {
          const errs = getValidationErrors(err);
          formRef.current?.setErrors(errs);
        } else if (err.response?.status === 400) {
          addToast({
            type: 'error',
            title: 'Ops...',
            description: err.response?.data?.message || 'Tente novamente',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, onClose, onSuccess, teacher, signOut, token],
  );

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      contentStyle={{ width: '90vw', maxWidth: 400, height: '90vh' }}
    >
      <ModalContent>
        <h1>{teacher?.name || 'Novo professor'}</h1>
        <Form ref={formRef} onSubmit={handleInfosSubmit}>
          <div className="inputs">
            <FileInput
              name="image"
              className="input"
              placeholder="Imagem"
              label="Imagem"
              prevImg={teacher?.image ? [teacher.image] : []}
            />
            <Input
              type="text"
              name="name"
              className="input"
              mask={['']}
              placeholder="Nome"
              label="Nome"
              defaultValue={teacher?.name}
              containerStyle={{ marginTop: 24 }}
            />
            <Input
              type="text"
              name="occupation"
              className="input"
              mask={['']}
              placeholder="Profissão"
              label="Profissão"
              defaultValue={teacher?.occupation}
              containerStyle={{ marginTop: 24 }}
            />
            <Textarea
              name="description"
              placeholder="Descrição"
              label="Descrição"
              defaultValue={teacher?.description}
              rows={8}
            />
            <button type="submit" disabled={loading}>
              Salvar
            </button>
          </div>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalEdit;
