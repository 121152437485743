import styled from 'styled-components';

export const Container = styled.div`
  margin: 2rem auto;
  width: 90%;
  padding: 10px 15px;

  .header {
    display: flex;
    justify-content: space-evenly;
    margin: 2rem 0;
  }

  .option-btn {
    position: absolute;
    top: 0.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &.delete-btn {
      right: 0.5rem;
    }

    &.edit-btn {
      right: 2rem;
    }
  }

  .partners {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    justify-items: center;
    margin: 1rem 0 2rem;

    .partner-item {
      box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 10%);
      padding: 0.5rem;
      border-radius: 4px;
      display: flex;
      width: 100%;
      position: relative;

      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
        border: 1px solid #aaa;
      }

      .infos {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 1rem;

        span {
          color: #777;
        }

        a, strong {
          text-decoration: none;
          color: #21a4ac;
        }
      }
    }
  }

  .banners {
    margin-top: 1rem;

    .banner-item {
      box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 10%);
      padding: 2rem 0.5rem 0.5rem;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 2rem;
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
        border: 1px solid #aaa;
      }

      .infos {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        span {
          color: #777;
        }

        a, strong {
          text-decoration: none;
          color: #21a4ac;
        }
      }
    }
  }
`;

export const BtnAdd = styled.button`
  display: flex;
  align-items: center;
  padding: 1rem 4rem;
  border-radius: 4px;
  border: 3px solid #21a4ac;
  color: #21a4ac;
  background-color: #fff;
  transition: 200ms ease-in-out;

  &:hover {
    background-color: #21a4ac;
    color: #fff
  }
`;
