import React, { useRef, useCallback } from 'react';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import { ModalContent, Form } from './styles';
import { useAuth } from '../../../hooks/Auth';
import { useToast } from '../../../hooks/Toast';
import toBase64 from '../../../utils/toBase64';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import FileInput from '../../../components/FileInput';
import { IBanner } from '..';
import Select from '../../../components/Select';

interface ISendData {
  image: FileList;
  link: string;
  order: number;
  page: string;
  isAd: string;
}

interface IModalReviewProps {
  banner?: IBanner;
  open: boolean;
  onClose: () => void;
  closeMenu?: () => void;
  onSuccess: () => void;
}

const ModalEditBanner: React.FC<IModalReviewProps> = ({
  open,
  onClose,
  banner,
  onSuccess,
}) => {
  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const handleInfosSubmit = useCallback(
    async (data: ISendData) => {
      try {
        formRef.current?.setErrors({});

        const Schema = yup.object().shape({
          link: yup.string().url('Digite um link válido').notRequired(),
          isAd: yup.string().required('O campo anúncio é obrigatório'),
          page: yup.string().required('O campo página é obrigatório'),
          order: yup
            .number()
            .typeError('A ordem deve ser um número')
            .required('O campo ordem é obrigatório'),
          hasImage: yup.boolean(),
          image: yup.mixed().when('hasImage', {
            is: true,
            then: yup.array().of(
              yup
                .mixed()
                .required('A imagem é obrigatória')
                .test(
                  'fileFormat',
                  'Formato não suportado',
                  value =>
                    value &&
                    ['image/jpg', 'image/jpeg', 'image/png'].includes(
                      value.type,
                    ),
                ),
            ),
            otherwise: yup
              .array()
              .of(
                yup
                  .string()
                  .url('A URL não é válida')
                  .required('A URL da imagem antiga é obrigatória'),
              ),
          }),
        });

        await Schema.validate(
          {
            ...data,
            hasImage: !!data.image,
            image: data.image ? Object.values(data.image) : [banner?.image],
          },
          { abortEarly: false },
        );

        const payload = {
          idBanner: banner?.id || undefined,
          ...data,
          image:
            data.image.length > 0
              ? await toBase64(data.image?.[0] as File)
              : undefined,
        };

        await api.post('/save-banner', payload, {
          headers: { authorization: token },
        });

        onClose();
        onSuccess();
        addToast({
          title: `${banner?.id ? 'Alterado' : 'Salvo'} com sucesso`,
          type: 'success',
        });
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else if (err instanceof yup.ValidationError) {
          const errs = getValidationErrors(err);
          formRef.current?.setErrors(errs);
        } else if (err.response?.status === 400) {
          addToast({
            type: 'error',
            title: 'Ops...',
            description: err.response?.data?.message || 'Tente novamente',
          });
        }
      }
    },
    [addToast, onClose, onSuccess, banner, signOut, token],
  );

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      contentStyle={{ width: '90vw', maxWidth: 400, height: '90vh' }}
    >
      <ModalContent>
        <Form ref={formRef} onSubmit={handleInfosSubmit}>
          <div className="inputs">
            <FileInput
              name="image"
              className="input"
              placeholder="Imagem"
              label="Imagem"
              prevImg={banner?.image ? [banner.image] : []}
            />
            <Input
              type="text"
              name="link"
              className="input"
              mask={['']}
              placeholder="Link"
              label="Link"
              defaultValue={banner?.link}
              containerStyle={{ marginTop: 24 }}
            />
            <Input
              type="number"
              name="order"
              className="input"
              mask={['']}
              placeholder="Ordem"
              label="Ordem"
              defaultValue={banner?.order}
              containerStyle={{ marginTop: 24 }}
            />
            <Select
              label="Página"
              name="page"
              values={[
                { label: 'inicio', value: 'inicio' },
                { label: 'videoArtigos', value: 'videoArtigos' },
                { label: 'videoAulas', value: 'videoAulas' },
                { label: 'clinicas', value: 'clinicas' },
                { label: 'webinar', value: 'webinar' },
              ]}
              defaultValue={
                banner?.page
                  ? { label: banner.page, value: banner.page }
                  : undefined
              }
            />
            <Select
              label="Anúncio"
              name="isAd"
              values={[
                { label: 'Sim', value: 'true' },
                { label: 'Não', value: 'false' },
              ]}
              defaultValue={
                banner
                  ? {
                      label: banner.isAd ? 'Sim' : 'Não',
                      value: banner.isAd ? 'true' : 'false',
                    }
                  : undefined
              }
            />
            <button type="submit">Salvar</button>
          </div>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditBanner;
