import React from 'react';

import { FiMapPin, FiBriefcase } from 'react-icons/fi';
import { Container, Brand } from './styles';
import Item from './Item';
import { useMenu } from '../../hooks/Menu';

const Menu: React.FC = () => {
  const { isShow } = useMenu();

  return (
    <Container isShow={isShow}>
      <Brand>
        <h1>iSPORT</h1>
        <h3>Administração</h3>
      </Brand>
      <Item to="/seja-profissional-parceiro" name="public-professional">
        <FiBriefcase size={20} />
        Profissional
      </Item>
      <Item to="/seja-empresa-parceira" name="public-local">
        <FiMapPin size={20} />
        Local / Empresa
      </Item>
    </Container>
  );
};

export default Menu;
