import React, { useRef, useCallback } from 'react';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import { ModalContent, Form } from './styles';
import { useAuth } from '../../../hooks/Auth';
import { useToast } from '../../../hooks/Toast';
import toBase64 from '../../../utils/toBase64';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import FileInput from '../../../components/FileInput';
import { IPartner } from '..';

interface ISendData {
  image: FileList;
  link: string;
  alt: string;
  order: number;
}

interface IModalReviewProps {
  partner?: IPartner;
  open: boolean;
  onClose: () => void;
  closeMenu?: () => void;
  onSuccess: () => void;
}

const ModalEditPartner: React.FC<IModalReviewProps> = ({
  open,
  onClose,
  partner,
  onSuccess,
}) => {
  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const handleInfosSubmit = useCallback(
    async (data: ISendData) => {
      try {
        formRef.current?.setErrors({});

        const Schema = yup.object().shape({
          link: yup.string().required('O campo link é obrigatório'),
          alt: yup.string().required('O campo nome é obrigatório'),
          order: yup
            .number()
            .typeError('A ordem deve ser um número')
            .required('O campo ordem é obrigatório'),
          hasImage: yup.boolean(),
          image: yup.mixed().when('hasImage', {
            is: true,
            then: yup.array().of(
              yup
                .mixed()
                .required('A imagem é obrigatória')
                .test(
                  'fileFormat',
                  'Formato não suportado',
                  value =>
                    value &&
                    ['image/jpg', 'image/jpeg', 'image/png'].includes(
                      value.type,
                    ),
                ),
            ),
            otherwise: yup
              .array()
              .of(
                yup
                  .string()
                  .url('A URL não é válida')
                  .required('A URL da imagem antiga é obrigatória'),
              ),
          }),
        });

        await Schema.validate(
          {
            ...data,
            hasImage: !!data.image,
            image: data.image ? Object.values(data.image) : [partner?.image],
          },
          { abortEarly: false },
        );

        const payload = {
          idPartner: partner?.id || undefined,
          ...data,
          image:
            data.image.length > 0
              ? await toBase64(data.image?.[0] as File)
              : undefined,
        };

        await api.post('/save-partner', payload, {
          headers: { authorization: token },
        });

        onClose();
        onSuccess();
        addToast({
          title: `${partner?.id ? 'Alterado' : 'Salvo'} com sucesso`,
          type: 'success',
        });
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else if (err instanceof yup.ValidationError) {
          const errs = getValidationErrors(err);
          formRef.current?.setErrors(errs);
        } else if (err.response?.status === 400) {
          addToast({
            type: 'error',
            title: 'Ops...',
            description: err.response?.data?.message || 'Tente novamente',
          });
        }
      }
    },
    [addToast, onClose, onSuccess, partner, signOut, token],
  );

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      contentStyle={{ width: '90vw', maxWidth: 400, height: '90vh' }}
    >
      <ModalContent>
        <Form ref={formRef} onSubmit={handleInfosSubmit}>
          <div className="inputs">
            <FileInput
              name="image"
              className="input"
              placeholder="Imagem"
              label="Imagem"
              prevImg={partner?.image ? [partner.image] : []}
            />
            <Input
              type="text"
              name="link"
              className="input"
              mask={['']}
              placeholder="Link"
              label="Link"
              defaultValue={partner?.link}
              containerStyle={{ marginTop: 24 }}
            />
            <Input
              type="text"
              name="alt"
              className="input"
              mask={['']}
              placeholder="Nome"
              label="Nome"
              defaultValue={partner?.alt}
              containerStyle={{ marginTop: 24 }}
            />
            <Input
              type="number"
              name="order"
              className="input"
              mask={['']}
              placeholder="Ordem"
              label="Ordem"
              defaultValue={partner?.order}
              containerStyle={{ marginTop: 24 }}
            />
            <button type="submit">Salvar</button>
          </div>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditPartner;
