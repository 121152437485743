import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

export interface ILinkProps extends LinkProps {
  $isActive?: boolean;
}

export const Container = styled(Link)<ILinkProps>`
  width: 100%;
  padding: 15px 5px;
  text-align: center;
  border: none;
  border-radius: 0;
  transition: all 0.4s;
  text-align: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  border-top: 1px #eee solid;
  display: flex;
  align-items: center;

  ${props =>
    props.$isActive &&
    css`
      background: #21a4ac;
      color: #fff;
    `}

  svg {
    margin: 0 20% 0 5%;
  }

  &:hover {
    background: #769f47;
    color: #fff;
  }
`;
