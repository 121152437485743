/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { FiPlay, FiImage, FiList } from 'react-icons/fi';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { Container } from './styles';
import Item from './Item';
import { useMenu } from '../../hooks/Menu';

const Menu: React.FC = () => {
  const { isShow } = useMenu();

  return (
    <Container isShow={isShow}>
      {/* <Item to="/indicators" name="indicators">
        <FiBarChart2 size={20} />
        Indicadores
      </Item> */}
      {/* <Item to="/users" name="users">
        <FiUser size={20} />
        Usuários
      </Item> */}
      <Item to="/professores" name="professores">
        <FaChalkboardTeacher size={20} />
        Professores
      </Item>
      <Item to="/categorias" name="categorias">
        <FiList size={20} />
        Categorias
      </Item>
      <Item to="/banners-e-parceiros" name="banners-e-parceiros">
        <FiImage size={20} />
        Banners e Parceiros
      </Item>
      <Item to="/videos" name="videos">
        <FiPlay size={20} />
        Videos
      </Item>
      <Item to="/afiliados" name="videos">
        <FaChalkboardTeacher size={20} />
        Afiliados
      </Item>
      {/* <Item to="/challenges" name="challenges">
        <GiSportMedal size={20} />
        Desafios
      </Item> */}
      {/* <Item to="/events" name="events">
        <FiCalendar size={20} />
        Eventos
      </Item> */}
      {/* <Item to="/sports" name="sports">
        <FiAward size={20} />
        Esportes / Categorias
      </Item> */}
    </Container>
  );
};

export default Menu;
