import styled from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.div`
  .input {
    display: flex;
    align-items: center;
    background: #fff;
    width: 100%;
    font-size: 16px;
    padding: 10px 0;

    h1 {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
    }

    .react-select {
      flex: 1;
      background: transparent;
      border: 0;
      color: #404040;

      &::placeholder {
        color: #404040;
      }
    }

    svg {
      margin-right: 16px;
    }
  }

  & + div {
    margin-top: 24px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 15px;

  svg {
    margin: 0;
  }

  span {
    background: #ff4d4d;
    color: #fff;

    &::before {
      border-color: #ff4d4d transparent;
    }
  }
`;
