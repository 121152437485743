import React, { createContext, useCallback, useState, useContext } from 'react';

interface IMenuContext {
  active: string;
  isShow: boolean;
  isPublic: boolean;
  pages: string[];
  handleActivePage: (page: string) => void;
  handleShowMenu: () => void;
  handleIsPublic: (v: boolean) => void;
  setNewPage: (page: string) => void;
}

const MenuContext = createContext<IMenuContext>({} as IMenuContext);

const MenuProvider: React.FC = ({ children }) => {
  const [isShow, setIsShow] = useState(() => window.innerWidth > 950);
  const [pages, setPages] = useState<string[]>([]);
  const [isPublic, setIsPublic] = useState(false);

  const [active, setActive] = useState<string>(
    localStorage.getItem('@HealthPlayAdmin:page') || 'admin',
  );

  const setNewPage = useCallback(page => {
    setPages(old => [...old, page]);
  }, []);

  const handleActivePage = useCallback((page: string) => {
    setActive(page);
    localStorage.setItem('@HealthPlayAdmin:page', page);
  }, []);

  const handleShowMenu = useCallback(() => setIsShow(prev => !prev), []);

  const handleIsPublic = useCallback((v: boolean) => {
    setIsPublic(v);
  }, []);

  return (
    <MenuContext.Provider
      value={{
        active,
        isShow,
        isPublic,
        pages,
        handleActivePage,
        handleShowMenu,
        handleIsPublic,
        setNewPage,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): IMenuContext {
  const context = useContext(MenuContext);
  if (!context) throw new Error('useMenu must be used within an MenuProvider');
  return context;
}

export { useMenu, MenuProvider };
