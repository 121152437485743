import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';

import { useHistory } from 'react-router-dom';
import Input from '../../components/Input';

import { Container, Form, Button, Sides } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import loadingSVG from '../../assets/loading.svg';
import logoWhite from '../../assets/icon_logo_white.png';

interface IFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoadig] = useState(false);

  const history = useHistory();

  const handleSubmit = useCallback(
    async ({ email, password }: IFormData): Promise<void> => {
      setLoadig(() => true);
      formRef.current?.setErrors({});
      try {
        const schema = yup.object().shape({
          email: yup
            .string()
            .email('Digite um e-mail válido')
            .required('O campo e-mail é obrigatório'),
          password: yup.string().required('O campo senha é obrigatório'),
        });

        await schema.validate(
          { email, password },
          {
            abortEarly: false,
          },
        );

        await signIn(email, password);
        history.push('/professores');
      } catch (error) {
        const err = error as any;
        console.log(err);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: err.response?.data.message || 'Credenciais inválidas',
            description: 'Tente novamente',
          });
        }
      } finally {
        setLoadig(() => false);
      }
    },
    [signIn, history, addToast],
  );

  return (
    <Container>
      <Sides className="left">
        <div className="container">
          <img src={logoWhite} alt="Logo da HealthPlay" />
        </div>
      </Sides>
      <Sides className="right">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Entrar</h1>
          <h3>web admin</h3>
          <section>
            <Input
              mask={['']}
              name="email"
              type="email"
              placeholder="E-mail"
              label="E-MAIL"
            />
            <Input
              mask={['']}
              name="password"
              type="password"
              placeholder="Senha"
              label="SENHA"
            />
            {loading ? (
              <span>
                Carregando...
                <img src={loadingSVG} alt="loading logo" />
              </span>
            ) : (
              <Button type="submit">Logar</Button>
            )}
          </section>
        </Form>
      </Sides>
    </Container>
  );
};

export default Login;
