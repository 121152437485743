import axios from 'axios';

let baseURL;
const envBaseUrl = process.env.REACT_APP_API_BASE_URL;

switch (process.env.NODE_ENV) {
  case 'development':
    baseURL = envBaseUrl || 'http://localhost:3333/admin';
    break;
  default:
    baseURL = envBaseUrl || 'https://healthplay-api.herokuapp.com/admin';
}

const api = axios.create({
  baseURL,
});

export default api;
