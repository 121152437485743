// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useMemo, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FaPencilAlt } from 'react-icons/fa';
import { GrMoney, GrTrash, GrView } from 'react-icons/gr';

import {
  ActionContainer,
  Container,
  DeleteBtn,
  EditBtn,
  ModalContent,
} from './styles';
import { useAuth } from '../../../hooks/Auth';
import { useToast } from '../../../hooks/Toast';
import Modal from '../../../components/Modal';
import ModalEdit from './ModalEdit';
import api from '../../../services/api';
import { IAffiliated } from '..';

interface IItemListProps {
  affiliated: IAffiliated;
  reload: () => void;
}

interface IModalData {
  title?: string;
  description?: string;
  button: {
    text: string;
    color: '#ff4545' | '#5ECB43';
  };
  open: boolean;
}

const ItemList: React.FC<IItemListProps> = ({ affiliated, reload }) => {
  const [modal, setModal] = useState<IModalData>({
    title: 'Tem certeza de que deseja deletar este afiliado?',
    open: false,
    button: {
      text: 'Sim',
      color: '#ff4545',
    },
  });
  const [showModaledit, setShowModaEdit] = useState(false);

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const copyAffiliatedUrlToClipboard = async () => {
    const url = affiliated.affiliatedUrl;
    if (!url) return;
    await navigator.clipboard.writeText(url);
    addToast({
      title: 'Copiado!',
      description: 'Url do afiliado copiada: ' + url,
      type: 'success',
    });
  };

  const handleDelete = useCallback(async () => {
    try {
      await api.delete(`/affiliated/${affiliated.id}`, {
        headers: { authorization: token },
      });

      addToast({
        title: 'Afiliado deletado!',
        type: 'info',
      });
      setModal(old => ({ ...old, open: false }));
      reload();
    } catch (err) {
      if (err.response?.status === 401) {
        signOut();

        addToast({
          title: 'Você não tem permissão para isso',
          type: 'error',
        });
      } else {
        addToast({
          title: 'Ops...',
          description:
            err.response?.data?.message ||
            'Ocorreu algum erro, tente novamente mais tarde',
          type: 'error',
        });
      }
    }
  }, [addToast, reload, signOut, affiliated.id, token]);

  const ModalContainer = (): JSX.Element =>
    useMemo(
      () => (
        <Modal
          isOpen={modal.open}
          onClose={() => setModal(old => ({ ...old, open: false }))}
        >
          <ModalContent color={modal.button.color}>
            <h3>{modal.title}</h3>
            {modal.description && <p>{modal.description}</p>}
            <button type="button" onClick={handleDelete}>
              {modal.button.text}
            </button>
          </ModalContent>
        </Modal>
      ),
      [],
    );

  return (
    <Container>
      <div className="infos">
        <h2>{affiliated.name}</h2>
        <span>{affiliated.email}</span>
      </div>

      <div className="views">
        <div>
          <GrView style={{ fontSize: 30, marginRight: 5, opacity: 0.4 }} />
          <h3>{affiliated.urlViewCount}</h3>

          <h5>Visualizações</h5>
        </div>
        <div>
          <GrMoney style={{ fontSize: 30, marginRight: 5, opacity: 0.4 }} />
          <h3>{affiliated.urlPurchaseCount}</h3>
          <h5>Vendas</h5>
        </div>
        <a href="https://www.google.com">ver mais ></a>
      </div>
      <ActionContainer>
        Ativado?
        <input type="checkbox" checked={affiliated.isActive} disabled>
          {/* {affiliated.isLinkActived.toString()} */}
        </input>
        <button onClick={copyAffiliatedUrlToClipboard}>Copiar link</button>
      </ActionContainer>
      <div>
        <EditBtn onClick={() => setShowModaEdit(true)}>
          <FaPencilAlt color="#aaa" />
        </EditBtn>
        <DeleteBtn onClick={() => setModal(old => ({ ...old, open: true }))}>
          <GrTrash color="#aaa" />
        </DeleteBtn>
      </div>
      {modal.open && <ModalContainer />}
      {showModaledit && (
        <ModalEdit
          onClose={() => setShowModaEdit(false)}
          onSuccess={() => reload()}
          open={showModaledit}
          affiliated={affiliated}
        />
      )}
    </Container>
  );
};

export default ItemList;
