import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 0%;
  top: 80px;
  padding: 30px;
  overflow: hidden;
  z-index: 99999999;
`;
