import React, { useEffect } from 'react';
import { Container } from './styles';

import LoadingSVG from '../../assets/loading.svg';

interface IProps {
  show?: boolean;
  opacity?: number;
}

const Loading: React.FC<IProps> = ({ show = false, opacity = 1 }) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    if (show) document.body.style.overflowY = 'hidden';
    else document.body.style.overflowY = 'auto';
  }, [show]);

  return (
    <Container style={{ display: show ? 'block' : 'none', opacity }}>
      <img src={LoadingSVG} alt="Loading..." />
      <h3>carregando...</h3>
    </Container>
  );
};

export default Loading;
