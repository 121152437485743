// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useMemo, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FaPencilAlt } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { IVideo } from '..';

import { Container, DeleteBtn, EditBtn, ModalContent } from './styles';
import { useAuth } from '../../../hooks/Auth';
import { useToast } from '../../../hooks/Toast';
import Modal from '../../../components/Modal';
import api from '../../../services/api';

interface IItemListProps {
  video: IVideo;
  reload: () => void;
  type: 'aula' | 'artigo' | 'clinica';
}

interface IModalData {
  title?: string;
  description?: string;
  button: {
    text: string;
    color: '#ff4545' | '#5ECB43';
  };
  open: boolean;
}

const ItemList: React.FC<IItemListProps> = ({ video, reload, type }) => {
  const [modal, setModal] = useState<IModalData>({
    title: 'Tem certeza de que deseja deletar este curso?',
    open: false,
    button: {
      text: 'Sim',
      color: '#ff4545',
    },
  });

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const handleDelete = useCallback(async () => {
    try {
      await api.delete('/delete-video', {
        data: { videoId: video.id, type: type },
        headers: { authorization: token },
      });

      addToast({
        title: 'Professor deletado!',
        type: 'info',
      });
      setModal(old => ({ ...old, open: false }));
      reload();
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 401) {
        signOut();

        addToast({
          title: 'Você não tem permissão para isso',
          type: 'error',
        });
      } else {
        addToast({
          title: 'Ops...',
          description:
            err.response?.data?.message ||
            'Ocorreu algum erro, tente novamente mais tarde',
          type: 'error',
        });
      }
    }
  }, [video.id, type, token, addToast, reload, signOut]);

  const ModalContainer = (): JSX.Element =>
    useMemo(
      () => (
        <Modal
          isOpen={modal.open}
          onClose={() => setModal(old => ({ ...old, open: false }))}
        >
          <ModalContent color={modal.button.color}>
            <h3>{modal.title}</h3>
            {modal.description && <p>{modal.description}</p>}
            <button type="button" onClick={handleDelete}>
              {modal.button.text}
            </button>
          </ModalContent>
        </Modal>
      ),
      [],
    );

  return (
    <Container>
      <div className="image" style={{ backgroundImage: `url(${video.image})` }}>
        {/* <img src={iconPlayer} alt="Play logo" /> */}
      </div>
      <div className="infos">
        <strong>{video.name}</strong>
        <p>{video.short_description}</p>
      </div>
      <div className="more-infos">
        <strong>Duração</strong>
        <span>{video.duration}</span>
        <strong>Professor</strong>
        <span>{video.teacher}</span>
      </div>
      <EditBtn to={`/video/${type}/${video.id}`}>
        <FaPencilAlt color="#aaa" />
      </EditBtn>
      <DeleteBtn onClick={() => setModal(old => ({ ...old, open: true }))}>
        <GrClose color="#aaa" />
      </DeleteBtn>
      {modal.open && <ModalContainer />}
    </Container>
  );
};

export default ItemList;
