import * as yup from 'yup';

export default async (data: any) => {
  const Schema = yup.object().shape({
    type: yup.mixed().required('Selecione um tipo'),
    name: yup.string().required('O campo nome é obrigatório'),
    teacher: yup.string().required('O campo professor é obrigatório'),
    subCategory: yup.string().required('A subcategoria é obrigatória'),
    short_description: yup
      .string()
      .required('O campo descrição curta é obrigatório'),
    long_description: yup
      .string()
      .required('O campo descrição longa é obrigatório'),
    hasId: yup.boolean(),
    image: yup.mixed().when('hasId', {
      is: false,
      then: yup
        .array()
        .of(
          yup
            .mixed()
            .required('A imagem é obrigatória')
            .test(
              'fileFormat',
              'Formato não suportado',
              value =>
                value &&
                ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type),
            ),
        )
        .min(1, 'A imagem é obrigatória'),
      otherwise: yup.array().of(yup.mixed().notRequired()),
    }),
    video: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('O nome é obrigatório'),
          duration: yup.string().required('A duração é obrigatória'),
          description: yup.string().required('A descrição é obrigatória'),
          thumbnail: yup.mixed().when('hasId', {
            is: false,
            then: yup
              .array()
              .of(
                yup
                  .mixed()
                  .required('A thumbnail é obrigatória')
                  .test(
                    'fileFormat',
                    'Formato não suportado',
                    value =>
                      value &&
                      ['image/jpg', 'image/jpeg', 'image/png'].includes(
                        value.type,
                      ),
                  ),
              )
              .min(1, 'A thumbnail é obrigatória'),
            otherwise: yup.array().of(yup.mixed().notRequired()),
          }),
          link: yup
            .string()
            .url('Digite uma URL válida')
            .required('O link do video é obrigatório'),
        }),
      )
      .min(1, 'Os videos são obrigatórios'),
  });

  return Schema.validate(data, { abortEarly: false });
};
