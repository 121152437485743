import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  justify-items: center;
  margin: 2rem auto;
  width: 90%;
  padding: 10px 15px;

  .categories {
    max-width: 500px;
    width: 100%;

    strong {
      font-size: 1.2rem;
    }

    .new-category {
      margin-left: 1rem;
      padding: 0.5rem;
      border: none;
      border-radius: 4px;
      color: #fff;
      font-weight: 600;
      background-color: #21a4ac;

      &:hover {
        background-color: ${shade(0.2, '#21a4ac')};
      }
    }

    .input-wrapper {
      display: flex;
      padding: 0;
      margin-top: 1rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      overflow: hidden;

      input {
        width: 100%;
        border: none;
        padding: 1rem;
      }

      button {
        width: 90px;
        height: 50px;
        border: none;
        color: #fff;
        font-weight: 600;
        background-color: #21a4ac;

        &:hover {
          background-color: ${shade(0.2, '#21a4ac')};
        }
      }
    }

    .select {
      margin-top: 1rem;
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #777;
    }

    .category-list {
      padding: 1rem;
      margin-top: 2rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      max-height: 350px;
      overflow-y: auto;

      .category-item {
        display: grid;
        grid-template-columns: 1fr 20px 20px;
        grid-column-gap: 10px;
        padding: 0.3rem 0;

        button {
          color: #ddd;
          background-color: transparent;
          cursor: pointer;
          border: none;
        }
      }
    }

    .subcategory-list {
      padding: 1rem;
      margin-top: 2rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      max-height: 350px;
      overflow-y: auto;

      .category-item {
        padding: 0.3rem 0;

        .subcategory-item {
          display: grid;
          grid-template-columns: 1fr 20px 20px;
          grid-column-gap: 10px;
          padding: 0.3rem 0 0.3rem 1rem;

          button {
            color: #ddd;
            background-color: transparent;
            cursor: pointer;
            border: none;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
interface IModalContainerProps {
  color: string;
}

export const ModalContent = styled.div<IModalContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 20px;
  }

  table {
    th {
      width: 100px;
      height: 30px;
      text-transform: uppercase;
      background-color: #5ecb43;
      color: #fff;
    }

    td {
      text-align: center;
    }
  }

  button {
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    background-color: ${({ color }) => color};
    color: #fff;

    &:hover {
      background-color: ${({ color }) => shade(0.2, color)};
    }
  }
`;

export const BtnAdd = styled.button`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #21a4ac;
  color: #fff;
  background-color: #21a4ac;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${shade(0.2, '#21a4ac')};
  }
`;
