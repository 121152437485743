import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';

import Loading from '../../components/Loading';

import { Container, Filter, Lista, BtnAdd } from './styles';
import api from '../../services/api';
import ItemList from './ItemList';
import { FaSearch } from 'react-icons/fa';

export interface IVideo {
  id: string;
  image: string;
  name: string;
  short_description: string;
  duration: number;
  teacher: string;
}

interface IFilterParams {
  search?: string;
  filterBy?: string;
}

const Videos: React.FC = () => {
  const { addToast } = useToast();
  const [videos, setVideos] = useState<{
    artigos: IVideo[];
    aulas: IVideo[];
    clinicas: IVideo[];
  }>();
  const [selectedTab, setSelectTab] = useState<
    'aulas' | 'artigos' | 'clinicas'
  >('aulas');
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const { token, signOut } = useAuth();

  const selectFilterBy = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    async function loadVideos(): Promise<void> {
      setIsLoading(() => true);
      try {
        const res = await api.get('/videos', {
          headers: { authorization: token },
        });
        setVideos(res.data);
      } catch (error) {
        const err = error as any;

        console.error(err);
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title: 'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
        }
      } finally {
        setIsLoading(() => false);
      }
    }

    loadVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const handleSearch = useCallback(
    async (event?: any) => {
      setIsLoading(() => true);
      const input = event?.target.value;
      const select = selectFilterBy.current?.value;
      const params: IFilterParams = {};

      if (input) params.search = input;
      if (select) params.filterBy = select;

      try {
        const { data } = await api.get('/videos', {
          headers: { Authorization: token },
          params,
        });

        setVideos(data);
      } catch (error) {
        const err = error as any;
        console.log(err);
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title: 'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
        }
      } finally {
        setIsLoading(() => false);
      }
    },
    [addToast, signOut, token],
  );

  return (
    <Container>
      <Filter>
        <h2>Videos</h2>
        <BtnAdd to="/video" type="button">
          Adicionar video
        </BtnAdd>
        <div className="search-container">
          <FaSearch size={30} color="#aaa" />
          <DebounceInput
            type="text"
            name="search"
            id="search"
            placeholder="Pesquisar video pelo nome"
            minLength={2}
            debounceTimeout={400}
            onChange={handleSearch}
          />
        </div>
        <div>
          <select
            ref={selectFilterBy}
            name="filterBy"
            id="filterBy"
            className="select"
            defaultValue=""
          >
            <option value="" disabled>
              Filtrar por
            </option>
            <option value="teacher.name">Filtrar por professor</option>
            <option value="name">Filtrar por nome</option>
          </select>
        </div>
      </Filter>
      <div className="wrapper">
        <div className="tab-btn-container">
          <button
            className={`tab-btn ${selectedTab === 'aulas' ? 'show' : ''}`}
            onClick={() => setSelectTab('aulas')}
          >
            VideoAulas ({videos?.aulas.length || 0})
          </button>
          <button
            className={`tab-btn ${selectedTab === 'artigos' ? 'show' : ''}`}
            onClick={() => setSelectTab('artigos')}
          >
            VideoArtigos ({videos?.artigos.length || 0})
          </button>
          <button
            className={`tab-btn ${selectedTab === 'clinicas' ? 'show' : ''}`}
            onClick={() => setSelectTab('clinicas')}
          >
            VideoClinicas ({videos?.clinicas.length || 0})
          </button>
        </div>
        <select
          name="filterBy"
          id="filterBy"
          className="select"
          defaultValue=""
        >
          <option value="" disabled>
            Ordenar por
          </option>
          <option value="all">Não sei</option>
          <option value="Publico">Também não sei</option>
        </select>
      </div>
      <Lista className={`tab-panel ${selectedTab === 'aulas' ? 'show' : ''}`}>
        {videos?.aulas.map(item => (
          <ItemList
            key={item.id}
            video={item}
            reload={() => setReload(old => !old)}
            type="aula"
          />
        ))}
      </Lista>
      <Lista className={`tab-panel ${selectedTab === 'artigos' ? 'show' : ''}`}>
        {videos?.artigos.map(item => (
          <ItemList
            key={item.id}
            video={item}
            reload={() => setReload(old => !old)}
            type="artigo"
          />
        ))}
      </Lista>
      <Lista
        className={`tab-panel ${selectedTab === 'clinicas' ? 'show' : ''}`}
      >
        {videos?.clinicas.map(item => (
          <ItemList
            key={item.id}
            video={item}
            reload={() => setReload(old => !old)}
            type="clinica"
          />
        ))}
      </Lista>
      <Loading show={isLoading} />
    </Container>
  );
};

export default Videos;
