import React, { InputHTMLAttributes, useState } from 'react';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  innerRef: React.RefObject<HTMLInputElement>;
}

const Input: React.FC<IProps> = ({ innerRef, ...rest }) => {
  const [input, setInput] = useState('');

  return (
    <div className="input-container">
      <input
        {...rest}
        ref={innerRef}
        value={input}
        onChange={e => setInput(e.target.value)}
      />
      <span className="input-counter">
        ({input.length}/{rest.maxLength})
      </span>
    </div>
  );
};

export default Input;
