import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface IOptionsType {
  value: any;
  label: string;
}

interface IProps {
  name: string;
  options: IOptionsType[];
  defaultValueSelected?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & IProps;

const RadioInput: React.FC<InputProps> = ({
  name,
  options,
  defaultValueSelected,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs: HTMLInputElement[]) {
        const checked = refs.find(ref => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs: HTMLInputElement[], value: any) {
        console.log(typeof value);
        console.log(value);
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {options.map((option, index) => {
        // console.log(defaultValue);
        // console.log(option.value);
        // console.log(defaultValue === option.value);

        return (
          <label key={option.value} htmlFor={name + index} className="radio">
            <input
              ref={elRef =>
                (inputRefs.current[index] = elRef as HTMLInputElement)
              }
              id={name + index}
              type="radio"
              name={fieldName}
              value={option.value}
              defaultChecked={defaultValueSelected === option.value}
            />
            <span className="radio-circle">
              <span className="radio-circle-fill"></span>
            </span>
            {option.label}
          </label>
        );
      })}
    </Container>
  );
};

export default RadioInput;
