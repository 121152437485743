import styled from 'styled-components';
import { Form as Unform } from '@unform/web';
import { shade } from 'polished';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled(Unform)`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .inputs {
    width: 100%;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    .col {
      flex: 1;
      align-items: center;
      justify-content: space-around;
      padding: 3%;
      min-width: 220px;
    }

    .bar {
      border-left: 1px solid #ddd;

      @media (max-width: 584px) {
        border: none;
      }
    }
  }

  .flex-column {
    flex-direction: column;
  }

  strong {
    margin-top: 10px;
  }

  button {
    margin-top: 48px;
    align-self: center;
    font-weight: 600;
    border-radius: 8px;
    border: 0;
    background: #39b100;
    color: #fff;
    padding: 16px 24px;

    &:hover {
      background-color: ${shade(0.2, '#39b100')};
    }
  }

  @media (max-width: 768px) {
    .inputs {
      width: 100%;
    }
  }
`;

export const Errors = styled.ul`
  margin-top: 20px;
  color: #ff4545;
  background-color: #ff454533;
  padding: 10px 10px 10px 30px;
  border-radius: 10px;
`;
