import React, { useEffect, useRef, useState, useCallback } from 'react';

import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { Container, Error } from './styles';

interface IInputProps extends InputProps {
  name: string;
  maskChar?: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  containerStyle?: React.CSSProperties;
}

const Input: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  mask,
  maskChar,
  label,
  containerStyle,
  onBlur,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      isFilled={isFilled}
      isFocused={isFocused}
      isCheckbox={rest.type === 'checkbox'}
      style={{
        ...containerStyle,
        ...(rest.type === 'hidden'
          ? {
              visibility: 'hidden',
              position: 'absolute',
            }
          : {}),
      }}
    >
      {label && rest.type !== 'checkbox' && (
        <div className="label">
          <strong>{label}</strong>
        </div>
      )}
      <div className="input">
        {Icon && <Icon size={20} />}

        {rest.type === 'checkbox' ? (
          <span>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                {...rest}
                defaultValue={defaultValue}
                style={{ marginRight: 10 }}
              />{' '}
              {label}
            </label>
          </span>
        ) : (
          <ReactInputMask
            mask={mask}
            maskChar={maskChar}
            onFocus={handleInputFocus}
            onBlur={e => {
              handleInputBlur();
              if (onBlur) onBlur(e);
            }}
            onChange={rest.onChange}
          >
            {(
              inputProps: JSX.IntrinsicAttributes &
                React.ClassAttributes<HTMLInputElement> &
                React.InputHTMLAttributes<HTMLInputElement>,
            ) => (
              <input
                {...inputProps}
                defaultValue={defaultValue}
                ref={inputRef}
                {...rest}
              />
            )}
          </ReactInputMask>
        )}
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </Container>
  );
};

export default Input;
