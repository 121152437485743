import styled, { css } from 'styled-components';

interface IMenuProps {
  isShow?: boolean;
}

export const Container = styled.aside<IMenuProps>`
  background: #fff;
  border-right: 1px #eee solid;
  z-index: 999;
  width: 230px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.4s;

  @media screen and (max-width: 950px) {
    position: absolute;
    top: 52px;
    left: -230px;

    ${props =>
      props.isShow &&
      css`
        left: 0;
      `}
  }
`;

export const Brand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;

  img {
    width: 50px;
    height: 50px;
  }

  h1 {
    color: #21a4ac;
    font-size: 34px;
  }

  h3 {
    color: #333;
    /* margin-left: 10px; */
  }
`;
