import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';

interface IModalProps {
  children: any;
  isOpen: boolean;
  contentStyle?: React.CSSProperties;
  onClose: () => void;
}

const Modal: React.FC<IModalProps> = ({
  children,
  isOpen,
  contentStyle,
  onClose,
}) => {
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={!false}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden';
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'auto';
      }}
      onRequestClose={onClose}
      isOpen={modalStatus}
      ariaHideApp={false}
      shouldFocusAfterRender
      style={{
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#fff',
          color: '#000000',
          borderRadius: '8px',
          border: 'none',
          minWidth: 300,
          height: 'fit-content',
          maxHeight: '90vh',
          ...contentStyle,
        },
        overlay: {
          backgroundColor: '#00000030',
          zIndex: 999,
        },
      }}
    >
      <FiX
        style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }}
        size={20}
        onClick={onClose}
      />
      {children}
    </ReactModal>
  );
};

export default Modal;
