import { shade } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 300px) 1fr minmax(100px, 150px);
  width: 100%;
  margin: 0 auto 20px;
  transition: box-shadow 0.4s;
  box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 10%);
  padding: 0.5rem;
  border-radius: 4px;
  position: relative;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 200px;
    border-radius: 8px;
    text-decoration: none;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      height: 50%;
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    span {
      margin-top: 0.5rem;
    }

    p {
      max-width: 600px;
    }
  }

  .more-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    span {
      margin-bottom: 0.5rem;
    }
  }

  p {
    grid-column: 1/3;
    margin-top: 20px;
  }

  button,
  a {
    text-decoration: none;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;

    svg {
      color: #aaa;
    }
  }
`;

export const EditBtn = styled(Link)`
  right: 40px !important;
`;

export const DeleteBtn = styled.button``;

interface IModalContainerProps {
  color: string;
}

export const ModalContent = styled.div<IModalContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 20px;
  }

  table {
    th {
      width: 100px;
      height: 30px;
      text-transform: uppercase;
      background-color: #5ecb43;
      color: #fff;
    }

    td {
      text-align: center;
    }
  }

  button {
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    background-color: ${({ color }) => color};
    color: #fff;

    &:hover {
      background-color: ${({ color }) => shade(0.2, color)};
    }
  }
`;

export const DeleteReviewTxt = styled.button`
  position: absolute;
  right: 10px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  color: #ff4545;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    color: #404040;
  }
`;
