import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth';
import { useMenu } from '../hooks/Menu';

interface IRouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<IRouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const { handleActivePage, handleIsPublic } = useMenu();
  const path = pathname.replace('/', '').replace(/\/(.*)/g, '');

  let active: string | null = null;
  if (path !== '' && path !== 'admin' && rest.path?.includes(path)) {
    active = path;
    handleActivePage(path);
  }

  if (pathname.includes(rest.path as string)) handleIsPublic(!isPrivate);

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return isPrivate === !!user || isPrivate === false ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : active ? `/${active}` : '/teachers',
            }}
          />
        );
      }}
    />
  );
};

export default Route;
