// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useMemo, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FaPencilAlt } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { ITeacher } from '..';

import { Container, DeleteBtn, EditBtn, ModalContent } from './styles';
import { useAuth } from '../../../hooks/Auth';
import { useToast } from '../../../hooks/Toast';
import Modal from '../../../components/Modal';
import ModalEdit from './ModalEdit';
import api from '../../../services/api';

interface IItemListProps {
  teacher: ITeacher;
  reload: () => void;
}

interface IModalData {
  title?: string;
  description?: string;
  button: {
    text: string;
    color: '#ff4545' | '#5ECB43';
  };
  open: boolean;
}

const ItemList: React.FC<IItemListProps> = ({ teacher, reload }) => {
  const [modal, setModal] = useState<IModalData>({
    title: 'Tem certeza de que deseja deletar este professor?',
    open: false,
    button: {
      text: 'Sim',
      color: '#ff4545',
    },
  });
  const [showModaledit, setShowModaEdit] = useState(false);

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const handleDelete = useCallback(async () => {
    try {
      await api.delete('/delete-teacher', {
        data: { teacherId: teacher.id },
        headers: { authorization: token },
      });

      addToast({
        title: 'Professor deletado!',
        type: 'info',
      });
      setModal(old => ({ ...old, open: false }));
      reload();
    } catch (err) {
      if (err.response?.status === 401) {
        signOut();

        addToast({
          title: 'Você não tem permissão para isso',
          type: 'error',
        });
      } else {
        addToast({
          title: 'Ops...',
          description:
            err.response?.data?.message ||
            'Ocorreu algum erro, tente novamente mais tarde',
          type: 'error',
        });
      }
    }
  }, [addToast, reload, signOut, teacher.id, token]);

  const ModalContainer = (): JSX.Element =>
    useMemo(
      () => (
        <Modal
          isOpen={modal.open}
          onClose={() => setModal(old => ({ ...old, open: false }))}
        >
          <ModalContent color={modal.button.color}>
            <h3>{modal.title}</h3>
            {modal.description && <p>{modal.description}</p>}
            <button type="button" onClick={handleDelete}>
              {modal.button.text}
            </button>
          </ModalContent>
        </Modal>
      ),
      [],
    );

  return (
    <Container>
      <img src={teacher.image} alt={teacher.name} />
      <div className="infos">
        <strong>{teacher.name}</strong>
        <span>{teacher.occupation}</span>
      </div>
      <p>{teacher.description}</p>
      <EditBtn onClick={() => setShowModaEdit(true)}>
        <FaPencilAlt color="#aaa" />
      </EditBtn>
      <DeleteBtn onClick={() => setModal(old => ({ ...old, open: true }))}>
        <GrClose color="#aaa" />
      </DeleteBtn>
      {modal.open && <ModalContainer />}
      {showModaledit && (
        <ModalEdit
          onClose={() => setShowModaEdit(false)}
          onSuccess={() => reload()}
          open={showModaledit}
          teacher={teacher}
        />
      )}
    </Container>
  );
};

export default ItemList;
