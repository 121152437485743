import React, { useRef, useCallback, useState, useEffect } from 'react';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/Auth';
import { useToast } from '../../../../hooks/Toast';
import { IAffiliated } from '../..';
import { ModalContent, Form } from './styles';
// import toBase64 from '../../../../utils/toBase64';
import getValidationErrors from '../../../../utils/getValidationErrors';

interface IView {
  countViews: number;
  countPayed: number;
  dateView: number;
}

interface ISendData {
  name: string;
  email?: string; // professor | outro
  isActive: boolean;
}

interface IModalReviewProps {
  affiliated?: IAffiliated;
  open: boolean;
  onClose: () => void;
  closeMenu?: () => void;
  onSuccess: () => void;
}

const ModalEdit: React.FC<IModalReviewProps> = ({
  open,
  onClose,
  affiliated,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(
    affiliated?.isActive ?? true,
  );

  useEffect(() => {
    console.log({ affiliated });
    setIsActive(affiliated?.isActive !== false ? true : false);
  }, [affiliated]);

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const handleInfosSubmit = useCallback(
    async (data: ISendData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);

        const formattedData = {
          ...data,
          isActive,
        };

        const Schema = yup.object().shape({
          name: yup.string().required('O campo nome é obrigatório'),
          email: yup.string().email('O campo email está incorreto'),
          isActive: yup.bool().required('O campo ativo é obrigatório'),
        });

        await Schema.validate(formattedData, { abortEarly: false });

        const payload = {
          name: formattedData.name,
          email: formattedData.email,
          isActive: formattedData.isActive,
        };
        if (affiliated?.id) {
          await api.put(`/affiliated/${affiliated?.id}`, payload, {
            headers: { authorization: token },
          });
        } else {
          await api.post(`/affiliated/`, payload, {
            headers: { authorization: token },
          });
        }

        onClose();
        onSuccess();
        addToast({
          title: `${affiliated?.id ? 'Alterado' : 'Salvo'} com sucesso`,
          type: 'success',
        });
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else if (err instanceof yup.ValidationError) {
          const errs = getValidationErrors(err);
          formRef.current?.setErrors(errs);
        } else if (err.response?.status) {
          addToast({
            type: 'error',
            title: 'Ops...',
            description: err.response?.data?.message || 'Tente novamente',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, onClose, onSuccess, affiliated, signOut, token, isActive],
  );

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      contentStyle={{ width: '90vw', maxWidth: 600, height: '90vh' }}
    >
      <ModalContent>
        <h1>{affiliated?.id ? affiliated?.name : 'Novo Afiliado'}</h1>
        <Form ref={formRef} onSubmit={handleInfosSubmit}>
          <div className="inputs">
            <Input
              type="text"
              name="name"
              className="input"
              mask={['']}
              placeholder="Nome"
              label="Nome"
              defaultValue={affiliated?.name}
              containerStyle={{ marginTop: 24 }}
            />
            <Input
              type="text"
              name="email"
              className="input"
              mask={['']}
              placeholder="Email"
              label="Email"
              defaultValue={affiliated?.email}
              containerStyle={{ marginTop: 24 }}
            />
            <label
              style={{
                marginTop: 24,
                display: 'flex',
                gap: 16,
                fontWeight: 'bold',
              }}
            >
              <span>Ativo</span>
              <input
                type="checkbox"
                name="isActive"
                className="input"
                checked={isActive as any}
                onChange={() => setIsActive(old => !old)}
              />
            </label>

            <button type="submit" disabled={loading}>
              Salvar
            </button>
          </div>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ModalEdit;
