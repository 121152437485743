import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 250px) 1fr;
  grid-template-rows: minmax(200px, 300px);
  grid-column-gap: 2rem;

  .file-inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;
  }

  .video-infos {
    display: grid;
    grid-gap: 1rem;

    .name {
      grid-column: 1/4;
    }

    .duration {
      grid-column: 4/5;
    }

    .linkVideo {
      grid-column: 1/5;
    }

    .description {
      grid-column: 1/5;
      grid-row: 3/10;

      div:not(.label), textarea {
        height: 96%;
      }
    }
  }
`;
