import React, { useCallback, useMemo, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { IPartner } from '.';
import Modal from '../../components/Modal';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import { ModalContent } from '../Teachers/ItemList/styles';
import ModalEditPartner from './ModalEditPartner';

interface IPartnerItemProps {
  partner: IPartner;
  reload: () => void;
}

const Partner: React.FC<IPartnerItemProps> = ({ partner, reload, ...rest }) => {
  const [showModaleditPartner, setShowModaEditPartner] = useState(false);
  const [modal, setModal] = useState(false);

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const handleDelete = useCallback(async () => {
    try {
      await api.delete('/delete-partner', {
        data: { idPartner: partner.id },
        headers: { authorization: token },
      });

      addToast({
        title: 'Partner deletado!',
        type: 'info',
      });
      setModal(false);
      reload();
    } catch (err) {
      if (err.response?.status === 401) {
        signOut();

        addToast({
          title: 'Você não tem permissão para isso',
          type: 'error',
        });
      } else {
        addToast({
          title: 'Ops...',
          description:
            err.response?.data?.message ||
            'Ocorreu algum erro, tente novamente mais tarde',
          type: 'error',
        });
      }
    }
  }, [addToast, reload, signOut, partner.id, token]);

  const ModalContainer = (): JSX.Element =>
    useMemo(
      () => (
        <Modal isOpen={modal} onClose={() => setModal(false)}>
          <ModalContent color="#ff4545">
            <h3>Tem certeza de que deseja deletar este parceiro?</h3>
            <button type="button" onClick={handleDelete}>
              Sim
            </button>
          </ModalContent>
        </Modal>
      ),
      [],
    );

  return (
    <>
      <div className="partner-item" {...rest}>
        <button
          type="button"
          className="option-btn edit-btn"
          onClick={() => setShowModaEditPartner(true)}
        >
          <FaPencilAlt color="#aaa" />
        </button>
        <button
          type="button"
          className="option-btn delete-btn"
          onClick={() => setModal(true)}
        >
          <GrClose color="#aaa" />
        </button>
        <img src={partner.image} alt={partner.alt} />
        <div className="infos">
          <span>
            Link:{' '}
            <a href={partner.link} target="_blank" rel="noopener noreferrer">
              {partner.link}
            </a>
          </span>
          <span>
            Ordenação: <strong>{partner.order}</strong>
          </span>
        </div>
      </div>
      {showModaleditPartner && (
        <ModalEditPartner
          onClose={() => setShowModaEditPartner(false)}
          onSuccess={reload}
          open={showModaleditPartner}
          partner={partner}
        />
      )}
      {modal && <ModalContainer />}
    </>
  );
};

export default Partner;
