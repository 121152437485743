import React, { useState, useEffect, useCallback } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';

import Loading from '../../components/Loading';

import { Container, Filter, Lista, BtnAdd } from './styles';
import api from '../../services/api';
import ItemList from './ItemList';
import ModalEdit from './ItemList/ModalEdit';

export interface IAffiliated {
  id: number;
  name: string;
  email?: string;
  isActive: boolean;
  urlPurchaseCount: number;
  urlViewCount: number;
  readonly affiliatedUrl?: string;
}

interface IFilterParams {
  name?: string;
  status?: string;
}

const Affiliated: React.FC = () => {
  const { addToast } = useToast();
  const [affiliatedList, setAffiliatedList] = useState<IAffiliated[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showModaledit, setShowModaEdit] = useState(false);

  const { token, signOut } = useAuth();

  useEffect(() => {
    async function loadAffiliated(): Promise<void> {
      setIsLoading(true);
      try {
        const { data } = await api.get<IAffiliated[]>('/affiliated', {
          headers: {
            authorization: token,
          },
        });

        setAffiliatedList(data);
      } catch (err) {
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title: 'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
        }
      } finally {
        setIsLoading(false);
      }
    }

    loadAffiliated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const handleSearch = useCallback(
    async (event: any) => {
      setIsLoading(() => true);
      const input = event.target.value;
      const params: IFilterParams = {};

      if (input) params.name = input;

      try {
        const { data } = await api.get<IAffiliated[]>('/affiliated', {
          headers: {
            Authorization: token,
          },
          params,
        });

        setAffiliatedList(data);

        if (data.length <= 0) {
          addToast({
            title: 'Nenhum afiliado encontrado',
            type: 'info',
          });
        }
      } catch {
        addToast({
          title: 'Você nao tem permissão para isto',
          type: 'error',
        });
        signOut();
      } finally {
        setIsLoading(() => false);
      }
    },
    [addToast, signOut, token],
  );

  return (
    <Container>
      <Filter>
        <div className="title">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <h1
              style={{
                fontSize: '2rem',
              }}
            >
              Afiliados
            </h1>
            <BtnAdd type="button" onClick={() => setShowModaEdit(true)}>
              Adicionar afiliado
            </BtnAdd>
          </div>
        </div>
        <DebounceInput
          type="text"
          name="search"
          id="search"
          placeholder="Pesquisar afiliado pelo nome"
          minLength={2}
          debounceTimeout={400}
          onChange={handleSearch}
        />
      </Filter>
      <Lista>
        {affiliatedList?.map(item => (
          <ItemList
            key={item.id}
            affiliated={item}
            reload={() => setReload(old => !old)}
          />
        ))}
      </Lista>
      <Loading show={isLoading} />
      {showModaledit && (
        <ModalEdit
          onClose={() => setShowModaEdit(false)}
          onSuccess={() => setReload(old => !old)}
          open={showModaledit}
        />
      )}
    </Container>
  );
};

export default Affiliated;
