import React, { createContext, useCallback, useState, useContext } from 'react';
import { isBefore, addHours } from 'date-fns';
import api from '../services/api';

interface IUserData {
  id: string;
  email: string;
  isAdmin: number;
}

interface IAuthContext {
  user: IUserData;
  token: string;
  signIn(email: string, password: string): Promise<void>;
  signOut(): void;
}

interface IAuthState {
  token: string;
  user: IUserData;
}

const AuthContext = createContext({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@HealthPlayAdmin:token');
    const time = localStorage.getItem('@HealthPlayAdmin:tokenExp');
    const tokenExp = time ? new Date(time) : null;
    const user = localStorage.getItem('@HealthPlayAdmin:user');

    if (tokenExp && isBefore(new Date(), tokenExp) && token && user)
      return { token, user: JSON.parse(user) } as IAuthState;
    return {} as IAuthState;
  });

  const signIn = useCallback(async (email: string, password: string) => {
    const res = await api.post('/login', {
      email: email.toLowerCase().trim(),
      password: password.trim(),
    });

    const { token, user } = res.data;

    if (user && token) {
      localStorage.setItem('@HealthPlayAdmin:token', token);
      localStorage.setItem('@HealthPlayAdmin:user', JSON.stringify(user));
      localStorage.setItem(
        '@HealthPlayAdmin:tokenExp',
        addHours(new Date(), 24).toString(),
      );

      setData({ token, user });
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@HealthPlayAdmin:token');
    localStorage.removeItem('@HealthPlayAdmin:user');
    localStorage.removeItem('@HealthPlayAdmin:page');
    localStorage.removeItem('@HealthPlayAdmin:tokenExp');

    setData({} as IAuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ ...data, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
}

export { useAuth, AuthProvider };
