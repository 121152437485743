import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TopCounters = styled.section`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px auto 20px;
  width: 90%;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 30px 15px;
`;

export const Filter = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 20px;
  width: 90%;
  padding: 10px 15px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    div {
      span {
        margin-right: 5px;
      }

      select {
        padding: 2px 5px;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: #333;
      }
    }
  }

  input {
    width: 100%;
    border: 1px #ccc solid;
    border-radius: 4px;
    padding: 5px 10px;
  }
`;

export const Lista = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  margin: 0 auto 20px;
  width: 90%;
  padding: 10px 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const BtnAdd = styled.button`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #21a4ac;
  color: #fff;
  background-color: #21a4ac;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${shade(0.2, '#21a4ac')};
  }
`;
