import React from 'react';
import Header from '../Header';
import Menu from '../Menu';
import PublicMenu from '../PublicMenu';
import { useMenu } from '../../hooks/Menu';

import { MainContent } from './styles';

const BaseLayout: React.FC = ({ children }) => {
  const { isPublic } = useMenu();

  return (
    <>
      <Header />
      <MainContent>
        {isPublic ? <PublicMenu /> : <Menu />}
        {children}
      </MainContent>
    </>
  );
};

export default BaseLayout;
