import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px;

    input[type="radio"] {
      width: 1px;
      height: 1px;
      visibility: hidden;

      &:checked ~ .radio-circle span.radio-circle-fill {
        display: block;
      }

      &:focus ~ span.radio-circle {
        border: 2px solid rgb(59, 153, 252);
        box-shadow: 0 0 5px 2px #00000030;
      }
    }

    span {
      cursor: pointer;

      &.radio-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        border: 2px solid #aaa;
        border-radius: 50%;
        margin-right: 10px;
      }

      &.radio-circle-fill {
        display: none;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: #21a4ac;
      }
    }

    label {
      cursor: pointer;
    }
  }
`;
