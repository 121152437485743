import React, { useState, useEffect, useCallback } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useToast } from '../../hooks/Toast';
import { useAuth } from '../../hooks/Auth';

import Loading from '../../components/Loading';

import { Container, Filter, Lista, BtnAdd } from './styles';
import api from '../../services/api';
import ItemList from './ItemList';
import ModalEdit from './ItemList/ModalEdit';

export interface ITeacher {
  id: string;
  name: string;
  image: string;
  description: string;
  occupation: string;
}

interface IFilterParams {
  name?: string;
  status?: string;
}

const Teachers: React.FC = () => {
  const { addToast } = useToast();
  const [teachers, setTeachers] = useState<ITeacher[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showModaledit, setShowModaEdit] = useState(false);

  const { token, signOut } = useAuth();

  useEffect(() => {
    async function loadTeachers(): Promise<void> {
      setIsLoading(() => true);
      try {
        const res = await api.get('/teachers', {
          headers: { authorization: token },
        });

        setTeachers(res.data.teachers);
      } catch (err) {
        if (err?.response?.status === 401) {
          addToast({
            title: 'Você nao tem permissão para isto',
            type: 'error',
          });
          signOut();
        } else {
          addToast({
            title: 'Ocorreu algum erro, atualize a página',
            type: 'error',
          });
        }
      } finally {
        setIsLoading(() => false);
      }
    }

    loadTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const handleSearch = useCallback(
    async (event: any) => {
      setIsLoading(() => true);
      const input = event.target.value;
      const params: IFilterParams = {};

      if (input) params.name = input;

      try {
        const { data } = await api.get('/teachers', {
          headers: { Authorization: token },
          params,
        });

        setTeachers(data.teachers);

        if (data.teachers.length <= 0) {
          addToast({
            title: 'Nenhum professor encontrado',
            type: 'info',
          });
        }
      } catch {
        addToast({
          title: 'Você nao tem permissão para isto',
          type: 'error',
        });
        signOut();
      } finally {
        setIsLoading(() => false);
      }
    },
    [addToast, signOut, token],
  );

  return (
    <Container>
      <Filter>
        <div className="title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2>Professores</h2>
            <BtnAdd type="button" onClick={() => setShowModaEdit(true)}>
              Adicionar professor
            </BtnAdd>
          </div>
        </div>
        <DebounceInput
          type="text"
          name="search"
          id="search"
          placeholder="Pesquisar professor pelo nome"
          minLength={2}
          debounceTimeout={400}
          onChange={handleSearch}
        />
      </Filter>
      <Lista>
        {teachers?.map(item => (
          <ItemList
            key={item.id}
            teacher={item}
            reload={() => setReload(old => !old)}
          />
        ))}
      </Lista>
      <Loading show={isLoading} />
      {showModaledit && (
        <ModalEdit
          onClose={() => setShowModaEdit(false)}
          onSuccess={() => setReload(old => !old)}
          open={showModaledit}
        />
      )}
    </Container>
  );
};

export default Teachers;
