import styled from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .preview {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    img {
      max-height: 50px;
      width: auto;
    }
  }

  strong {
    width: 100%;
    margin-bottom: 10px;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  & + div {
    margin-top: 24px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  width: 20px !important;
  margin-left: 15px;

  svg {
    margin: 0;
  }

  span {
    background: #ff4d4d;
    color: #fff;

    &::before {
      border-color: #ff4d4d transparent;
    }
  }
`;
