import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface IProps {
  name: string;
  label?: string;
  prevImg?: string[];
}

type InputProps = JSX.IntrinsicElements['input'] & IProps;

const FileInput: React.FC<InputProps> = ({ name, label, prevImg, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState<string[]>([defaultValue]);

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;

    if (!file) {
      setPreview([]);
    } else {
      const previewURL = Object.values(file).map(v => URL.createObjectURL(v));
      setPreview(previewURL);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files',
      clearValue(ref: HTMLInputElement) {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';
        setPreview([]);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview([value]);
      },
    });

    if (prevImg) setPreview([...prevImg]);
  }, [fieldName, prevImg, registerField]);

  return (
    <Container>
      <div className="preview">
        {preview.length > 0 &&
          preview.map(v => (
            <img key={Math.random()} src={v} alt="Preview" width="100" />
          ))}
      </div>
      {label && <strong>{label}</strong>}
      <div>
        <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </Container>
  );
};

export default FileInput;
