import React, { useState } from 'react';
import { useCallback } from 'react';
import { IVideoValuesTypes } from '../../pages/VideoEdit';
import Input from '../Input';
import Textarea from '../Textarea';
import ThumbnailInput from '../ThumbnailInput';

import { Container } from './styles';

interface IVideoInfoFormProps {
  index: number;
  defaultValues: IVideoValuesTypes;
  getValues: (data: IVideoValuesTypes) => void;
}

// This component must be inside a Unform Scope component
const VideoInfoForm: React.FC<IVideoInfoFormProps> = ({
  index,
  defaultValues,
  getValues,
}) => {
  const [values, setValues] = useState<IVideoValuesTypes>(
    {} as IVideoValuesTypes,
  );

  const listenInputChange = useCallback(
    (name: string, value?: string | File) => {
      setValues(old => ({ ...old, [name]: value || undefined }));
      getValues({ ...values, [name]: value || undefined });
    },
    [getValues, values],
  );

  return (
    <Container>
      <Input
        name={`video[${index}].id`}
        mask={['']}
        type="hidden"
        defaultValue={defaultValues?.id}
      />
      <div className="file-inputs">
        <ThumbnailInput
          name={`video[${index}].thumbnail`}
          label="Thumbnail"
          accept="image/jpg,image/jpeg,image/png"
          onChange={event => {
            event.persist();
            listenInputChange('thumbnail', event.target?.files?.[0]);
          }}
          prevImg={defaultValues?.thumbnail}
        />
      </div>
      <div className="video-infos">
        <div className="name">
          <Input
            name={`video[${index}].name`}
            placeholder="Digite o nome do video"
            label="Nome"
            mask={['']}
            onChange={event => {
              event.persist();
              listenInputChange('name', event.target?.value);
            }}
            defaultValue={defaultValues?.name}
          />
        </div>
        <div className="duration">
          <Input
            name={`video[${index}].duration`}
            placeholder="Digite o tempo do video"
            label="Duração"
            type="number"
            mask={['']}
            onChange={event => {
              event.persist();
              listenInputChange('duration', event.target?.value);
            }}
            defaultValue={defaultValues?.duration}
          />
        </div>
        <div className="linkVideo">
          <Input
            name={`video[${index}].link`}
            placeholder="Cole aqui o link do video"
            label="Link do video"
            mask={['']}
            onChange={event => {
              event.persist();
              listenInputChange('video', event.target?.value);
            }}
            defaultValue={defaultValues?.link}
          />
        </div>
        <div className="description">
          <Textarea
            name={`video[${index}].description`}
            label="Descrição curta"
            placeholder="Digite a descrição do video"
            onChange={event => {
              event.persist();
              listenInputChange('description', event.target?.value);
            }}
            defaultValue={defaultValues?.description}
          />
        </div>
      </div>
    </Container>
  );
};

export default VideoInfoForm;
