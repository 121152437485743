import React from 'react';
import { Container, ILinkProps } from './styles';
import { useMenu } from '../../../hooks/Menu';

interface ILinkItemProps extends ILinkProps {
  name: string;
}

const Item: React.FC<ILinkItemProps> = ({ name, children, ...rest }) => {
  const { active, handleActivePage, setNewPage, handleShowMenu } = useMenu();

  return (
    <Container
      $isActive={active === name}
      onClick={() => {
        handleActivePage(name);
        setNewPage(name);
        handleShowMenu();
      }}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default Item;
