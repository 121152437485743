import React, { useCallback, useMemo, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { IBanner } from '.';
import Modal from '../../components/Modal';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import { ModalContent } from '../Teachers/ItemList/styles';
import ModalEditBanner from './ModalEditBanner';

interface IBannerItemProps {
  banner: IBanner;
  reload: () => void;
}

const Banner: React.FC<IBannerItemProps> = ({ banner, reload, ...rest }) => {
  const [showModaleditBanner, setShowModaEditBanner] = useState(false);
  const [modal, setModal] = useState(false);

  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  const handleDelete = useCallback(async () => {
    try {
      await api.delete('/delete-banner', {
        data: { idBanner: banner.id },
        headers: { authorization: token },
      });

      addToast({
        title: 'Banner deletado!',
        type: 'info',
      });
      setModal(false);
      reload();
    } catch (err) {
      if (err.response?.status === 401) {
        signOut();

        addToast({
          title: 'Você não tem permissão para isso',
          type: 'error',
        });
      } else {
        addToast({
          title: 'Ops...',
          description:
            err.response?.data?.message ||
            'Ocorreu algum erro, tente novamente mais tarde',
          type: 'error',
        });
      }
    }
  }, [addToast, reload, signOut, banner.id, token]);

  const ModalContainer = (): JSX.Element =>
    useMemo(
      () => (
        <Modal isOpen={modal} onClose={() => setModal(false)}>
          <ModalContent color="#ff4545">
            <h3>Tem certeza de que deseja deletar este banner?</h3>
            <button type="button" onClick={handleDelete}>
              Sim
            </button>
          </ModalContent>
        </Modal>
      ),
      [],
    );

  return (
    <>
      <div className="banner-item" {...rest}>
        <button
          type="button"
          className="option-btn edit-btn"
          onClick={() => setShowModaEditBanner(true)}
        >
          <FaPencilAlt color="#aaa" />
        </button>
        <button
          type="button"
          className="option-btn delete-btn"
          onClick={() => setModal(true)}
        >
          <GrClose color="#aaa" />
        </button>
        <img key={banner.id} src={banner.image} alt="imagem de banner" />
        <div className="infos">
          <span>
            Link:{' '}
            <a href={banner.link} target="_blank" rel="noopener noreferrer">
              {banner.link}
            </a>
          </span>
          <span>
            Página: <strong>{banner.page}</strong>
          </span>
          <span>
            Ordenação: <strong>{banner.order}</strong>
          </span>
          <span>
            Propaganda: <strong>{banner.isAd ? 'Sim' : 'Não'}</strong>
          </span>
        </div>
      </div>
      {showModaleditBanner && (
        <ModalEditBanner
          onClose={() => setShowModaEditBanner(false)}
          onSuccess={reload}
          open={showModaleditBanner}
          banner={banner}
        />
      )}
      {modal && <ModalContainer />}
    </>
  );
};

export default Banner;
