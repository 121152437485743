import React, { TextareaHTMLAttributes, useState } from 'react';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  innerRef: React.RefObject<HTMLTextAreaElement>;
}

const Textarea: React.FC<IProps> = ({ innerRef, ...rest }) => {
  const [text, setText] = useState('');

  return (
    <div className="textarea-container">
      <textarea
        {...rest}
        ref={innerRef}
        value={text}
        onChange={e => setText(e.target.value)}
      />
      <span className="textarea-counter">
        ({text.length}/{rest.maxLength})
      </span>
    </div>
  );
};

export default Textarea;
